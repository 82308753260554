export enum GroupType {
    STANDARD = 1,
    MANAGED_EVERYONE = 2,
    MANAGED_FULL_USER = 3,
    EXT_SCIM = 5000,
}

export enum ClientGroupType {
    DEFAULT_GROUP = 1,
    EXTERNAL_GROUP = 2,
    MEASURE_GROUP = 3,
}
