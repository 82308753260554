import { z } from "zod";
import { zNumericId } from "./baseschemas";

export const zFileDto = z.object({
    id: zNumericId,
    parentId: zNumericId,
    filename: z.string(),
    hash: z.string(),
    size: z.number(),
    mimetype: z.string().nullable(),
    tableName: z.string(),
    createdById: zNumericId,
    createdAt: z.date(),
    updatedAt: z.date(),
});

export type FileDto = z.infer<typeof zFileDto>;

export const zFileListDto = z.array(zFileDto);

export type FileListDto = z.infer<typeof zFileListDto>;
