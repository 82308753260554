/**
 * Extract the FQDN from input string.
 *
 * - Remove protocol (http:// or https://)
 * - Remove optional www.
 * - converts to lowercase
 * - remove everything from first /
 */
export function sanitizeDomain(input: string): string {
    // trim first
    // strip optional protocol (http:// https://)
    // strip optional www.
    // strip everything after first / character
    const re = /(https?:\/\/)?(www\.)?([^/]*)/;
    const match = re.exec(input.trim().toLowerCase());
    return match?.[3] ?? "";
}

/**
 * Convert a nullable input value to a number.
 */
export function sanitizeNullableNumber(input: any): number | null {
    return input != null ? +input : null;
}

export const CustomFieldNameAllowedRegex = /^[a-z][A-Za-z0-9]*$/;
export const validateCustomFieldName = (input: string): boolean => CustomFieldNameAllowedRegex.test(input);
