import { z } from "zod";
import { GroupType } from "../constants";
import { zNumericId, zShortText } from "./baseschemas";

export const zBasicGroup = z.object({
    id: z.number(),
    nameEn: z.string(),
    descriptionEn: z.string(),
    nameDe: z.string(),
    descriptionDe: z.string(),
    userIds: zNumericId.array(),
    type: z.nativeEnum(GroupType),
    parentGroupId: zNumericId.nullable(),
    childGroupIds: zNumericId.array(),
    isVisible: z.boolean(),
});

export const zBasicGroupListDto = z.array(zBasicGroup);

export const zGroup = z.object({
    id: z.number(),
    nameEn: z.string(),
    descriptionEn: z.string(),
    nameDe: z.string(),
    descriptionDe: z.string(),
    isUsed: z.boolean(),
    userIds: zNumericId.array(),
    parentGroupId: zNumericId.nullable(),
    childGroupIds: zNumericId.array(),
    type: z.nativeEnum(GroupType),
    isVisible: z.boolean(),
});

export const zGroupListDto = z.array(zGroup);

export const zCreateGroupRequestBody = z.object({
    nameEn: zShortText.min(1),
    descriptionEn: zShortText,
    nameDe: zShortText.min(1),
    descriptionDe: zShortText,
    parentGroupId: zNumericId.nullable(),
    isVisible: z.boolean().default(true),
});

export const zUpdateGroupRequestBody = z.object({
    nameEn: zShortText.optional(),
    descriptionEn: zShortText.optional(),
    nameDe: zShortText.optional(),
    descriptionDe: zShortText.optional(),
    userIds: zNumericId.array(),
    isVisible: z.boolean().default(true),
});

export const zGroupRequestParam = z.object({
    groupId: zNumericId,
});

export type GroupDto = z.infer<typeof zGroup>;
export type GroupListDto = z.infer<typeof zGroupListDto>;
export type CreateGroupRequestBody = z.infer<typeof zCreateGroupRequestBody>;
export type GroupRequestParam = z.infer<typeof zGroupRequestParam>;
export type UpdateGroupRequestBody = z.infer<typeof zUpdateGroupRequestBody>;
export type UpdateGroupRequest = GroupRequestParam & UpdateGroupRequestBody;
