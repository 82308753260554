import { z } from "zod";

export const zGlobalSearchRequestQuery = z.object({
    query: z.string(),
});

const zSearchItem = z.object({
    id: z.number(),
    displayId: z.number(),
    title: z.string(),
    isActive: z.boolean(),
    type: z.enum(["measure", "idea"]),
});

const zSearchResultMatch = z.object({
    indices: z.array(z.tuple([z.number(), z.number()])),
    key: z.string().optional(),
    refIndex: z.number().optional(),
    value: z.string().optional(),
});

export const zSearchResult = z.object({
    item: zSearchItem,
    matches: z.array(zSearchResultMatch).optional(),
});

export const zSearchResultListDto = z.array(zSearchResult);

export type SearchResultMatch = z.infer<typeof zSearchResultMatch>;
export type SearchResultDto = z.infer<typeof zSearchResult>;
export type SearchResultListDto = z.infer<typeof zSearchResultListDto>;
