export enum PasswordValidityCriteria {
    NUMBER = 1,
    LOWER = 2,
    UPPER = 3,
    SPECIAL = 4,
    LENGTH = 5,
}

/**
 * Check if value matches the password requirements
 */
export function getPasswordWeaknesses(value: string): PasswordValidityCriteria[] {
    const criteria: { [x: number]: RegExp } = {
        [PasswordValidityCriteria.NUMBER]: /\d/,
        [PasswordValidityCriteria.LOWER]: /[a-z]/,
        [PasswordValidityCriteria.UPPER]: /[A-Z]/,
        [PasswordValidityCriteria.SPECIAL]: /[!#@$%^&*)(+=._-]/,
        [PasswordValidityCriteria.LENGTH]: /.{12,}/,
    };
    return Object.keys(criteria)
        .map((k) => +k)
        .filter((key) => !criteria[key].test(value));
}

export function isValidPassword(value: string): boolean {
    return getPasswordWeaknesses(value).length <= 0;
}
