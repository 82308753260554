import { z } from "zod";
import { TranslationType } from "../constants";
import { zNumericId } from "./baseschemas";
import { zAttributeOptions } from "./attribute-options";

export const zMappedMeasureAttributeDto = z.object({
    id: zNumericId,
    title: z.string(),
    type: z.string(),
    mandatory: z.boolean(),
    quantity: z.number().nullable(),
    tableName: z.string().nullable(),
    options: zAttributeOptions.nullable(),
    translate: z.nativeEnum(TranslationType).nullable(),
    order: z.number().nullable(),
    isCreatable: z.boolean().nullable(),
    categoryId: zNumericId.nullish(),
});

// this is not related to the db/backend entity "measure attribute". It maps to the AttributeBo and is intentionally named this way
// because the frontend does not know about the generic attribute implementation yet
export const zMeasureAttributeDto = z.preprocess((obj) => {
    if (obj === null || typeof obj !== "object") {
        return obj;
    }

    let quantity: unknown;
    const isMulti = (obj as any).isMulti;
    if (isMulti == null) {
        quantity = null;
    } else {
        quantity = isMulti ? 0 : 1;
    }

    const order = (obj as any).context?.order ?? null;
    const mandatory = (obj as any).context?.mandatory ?? null;
    const categoryId = (obj as any).context?.categoryId ?? null;

    return {
        ...obj,
        quantity,
        order,
        mandatory,
        categoryId,
    };
}, zMappedMeasureAttributeDto);

export type MeasureAttributeDto = z.infer<typeof zMeasureAttributeDto>;

export const zGetMeasureAttributesResponseDto = z.array(zMeasureAttributeDto);

export type GetMeasureAttributesResponseDto = z.infer<typeof zGetMeasureAttributesResponseDto>;
