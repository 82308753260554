import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";

export const zMeasureFieldV1 = extendApi(
    z.object({
        name: z.string().trim().min(1),
        field: z.string().trim().min(1),
        value: z.any(),
    }),
    {
        title: "Field",
        description: "Field definition and value.",
    },
);

export const zMeasureFieldListV1 = extendApi(z.array(zMeasureFieldV1), {
    title: "Field List",
    description: "List of fields on a measure.",
});

export type MeasureFieldDtoV1 = z.infer<typeof zMeasureFieldV1>;
export type MeasureFieldListDtoV1 = z.infer<typeof zMeasureFieldListV1>;
