import { AttributeTitle } from "../constants";

export class InternalToPublicStringMapper {
    private mapper: Map<string, string>;

    private reverseMapper: Map<string, string>;

    constructor(enumMap: Map<string, string>) {
        this.mapper = enumMap;
        this.reverseMapper = new Map([...enumMap.entries()].map((entry) => [entry[1], entry[0]]));
    }

    toPublic(value: string): string {
        return this.mapper.get(value) ?? value;
    }

    toInternal(value: string): string {
        return this.reverseMapper.get(value) ?? value;
    }
}

const InternalToPublicMap = [
    [AttributeTitle.CompanyId, "company"],
    [AttributeTitle.CostLeverId, "method"],
    [AttributeTitle.CurrentSupplier, "currentPartner"],
    [AttributeTitle.Departments, "department"],
    [AttributeTitle.Description, "description"],
    [AttributeTitle.DiscardReason, "discardReason"],
    [AttributeTitle.DiscardStatement, "discardStatement"],
    [AttributeTitle.NewSupplier, "newPartner"],
    [AttributeTitle.OriginatorClient, "sourceOfIdea"],
    [AttributeTitle.ProductGroupId, "materialGroup"],
    [AttributeTitle.Project, "tag"],
] as const;

export const AttributTitlePublicApiMapper = new InternalToPublicStringMapper(new Map(InternalToPublicMap));
