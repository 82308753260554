import { z } from "zod";
import { zNumericId } from "./baseschemas";

export const DEFAULT_LIMIT = 5;

export enum CommentParentType {
    Ideas = "ideas",
    Measures = "measures",
    SubTasks = "subTasks",
}

const zCommentStreamItemParentCommonFields = z.object({
    displayId: zNumericId.optional(),
    id: zNumericId,
    tableName: z.nativeEnum(CommentParentType),
    title: z.string(),
});

const zCommentStreamItemParentIdea = zCommentStreamItemParentCommonFields.extend({
    tableName: z.literal(CommentParentType.Ideas),
    responsiblePerson: zNumericId,
});

const zCommentStreamItemParentMeasure = zCommentStreamItemParentCommonFields.extend({
    tableName: z.literal(CommentParentType.Measures),
    responsiblePerson: zNumericId.nullable(),
    measureConfigName: z.string(),
});

const zCommentStreamItemParentSubtask = zCommentStreamItemParentCommonFields.extend({
    tableName: z.literal(CommentParentType.SubTasks),
    responsiblePerson: zNumericId.nullable(),
});

const zCommentStreamItem = z.object({
    id: zNumericId,
    comment: z.string(),
    createdAt: z.date(),
    createdById: zNumericId,
    parent: z.union([zCommentStreamItemParentIdea, zCommentStreamItemParentMeasure, zCommentStreamItemParentSubtask]),
    updatedAt: z.date(),
    updatedById: zNumericId,
});

export const zCommentStreamDto = z.object({
    minDate: z.string().datetime().nullable(),
    maxDate: z.string().datetime().nullable(),
    nextDate: z.string().datetime().nullable(),
    hasNextPage: z.boolean(),
    items: z.array(zCommentStreamItem),
});

export const zGetCommentStreamRequestQuery = z.object({
    minDate: z.string().datetime().nullish(),
    maxDate: z.string().datetime().nullish(),
    entities: z.array(z.nativeEnum(CommentParentType)).optional(),
    commentedBy: z.array(z.coerce.number()).optional(),
    responsiblePerson: z.array(z.coerce.number()).optional(),
});

export type CommentStreamDto = z.infer<typeof zCommentStreamDto>;
export type CommentStreamRequestQuery = z.infer<typeof zGetCommentStreamRequestQuery>;
