import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { zMinMaxString, zNumericId } from "../../baseschemas";
import { zIdNameV1 } from "../generics/idName";

export const zMeasureV1 = extendApi(
    z.object({
        id: zNumericId,
        displayId: zNumericId,
        mid: z.preprocess((mid) => Number(mid), z.number().int()), // client_iid
        measureConfig: zIdNameV1,
        title: extendApi(zMinMaxString, { example: "Make packaging thinner." }),

        // In later iterations of the API all relevant data should be supplied in this Endpoint for convenience.
        // The data should be served to the specific services.
        // This is a (non complete) list of possible extensions for later usage.
        // - calculations
        // - attachments
        // - comments
        // - gates -> is this useful?
        // ...
        // - calculatedFields
        // - counts (docs)
        // ...
        // - renderedFields -> e.g. description prepared as rendered Markdown
        // - history
        // - schema
        // - operations -> list of actions to call on the measure like close, close gate, reassign, claim, ...
    }),
    {
        title: "Measure",
        description: "A measure.",
    },
);

export const zMeasureListV1 = extendApi(z.array(zMeasureV1), {
    title: "Measure List",
    description: "List of measures.",
});

export type MeasureDtoV1 = z.infer<typeof zMeasureV1>;
export type MeasureListDtoV1 = z.infer<typeof zMeasureListV1>;
