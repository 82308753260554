import { z } from "zod";

export const zScopeDto = z.object({
    startDate: z.string().nullable(),
    endDate: z.string().nullable(),
    attributes: z.record(z.number().array()),
    currencyIds: z.number().array().optional(),
});

export type ScopeDto = z.infer<typeof zScopeDto>;
