export enum Operators {
    SmallerThan = "<",
    GreaterThan = ">",
    Equals = "=",
    NotEquals = "!=",
    Like = "LIKE",
    In = "IN",
    NotIn = "NOT_IN",
    Before = "BEFORE",
    Within = "WITHIN",
    InNext = "IN_NEXT",
    InMore = "IN_MORE",
    NotSet = "NOT_SET",
}
