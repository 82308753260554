import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { EffectFilterCurrencyField, GlobalCalculationIdentifier, MaxYear, MinYear } from "../../../constants";

export const zMeasureEffectV1 = extendApi(
    z.object({
        effectCategory: z.record(
            z.string().trim().min(1),
            z.strictObject({ id: z.preprocess((id) => Number(id), z.number()) }),
            // Don't specify the effectType (savings/changeoverCosts) here. In the first iteration this is always savings.
        ),
        generation: z.object({ name: z.nativeEnum(GlobalCalculationIdentifier) }), // { name: [enum] }
        month: z.preprocess((y) => Number(y), z.number().min(0).max(11)),
        year: z.preprocess(
            (y) => Number(y),
            z
                .number()
                .min(MinYear)
                .max(MaxYear - 1),
        ),
        type: z.nativeEnum(EffectFilterCurrencyField), // Initial, Effect, Price Hike
        effect: z.preprocess((id) => Number(id), z.number()),
        currency: z.preprocess((id) => Number(id), z.number()),
        // Period is always assumed as 1 in this iteration.
    }),
    {
        title: "Measure Effect",
        description: "A effect per month related to a category.",
    },
);

export const zMeasureEffectListV1 = extendApi(z.array(zMeasureEffectV1), {
    title: "Measure Effect List",
    description: "List of effects on a measure.",
});

export type MeasureEffectDtoV1 = z.infer<typeof zMeasureEffectV1>;
export type MeasureEffectListDtoV1 = z.infer<typeof zMeasureEffectListV1>;
