import { z } from "zod";

export const zTranslationDto = z.object({
    de: z.string(),
    en: z.string(),
    key: z.string(),
});

export type TranslationDto = z.infer<typeof zTranslationDto>;

export const zTranslationListDto = z.array(zTranslationDto);

export type TranslationListDto = z.infer<typeof zTranslationListDto>;
