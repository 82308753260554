import { z } from "zod";
import { zNumericId } from "../baseschemas";

export const zSuperAdminGetTreeNodeRequestParam = z.object({
    clientId: zNumericId,
});

export type SuperAdminGetTreeNodeRequestParam = z.infer<typeof zSuperAdminGetTreeNodeRequestParam>;

export const zUploadTreeNodeRequestBody = z.object({
    clientId: zNumericId,
    attributeId: zNumericId,
});
