import { z } from "zod";
import { EffectType, MeasureStatus } from "../constants";
import { zNumericId } from "./baseschemas";

const zFilteredEffectCategoryDto = z.object({
    id: zNumericId,
    effectType: z.nativeEnum(EffectType),
    categoryFields: z.record(z.string(), z.number()),
    calculationFields: z.record(z.string(), z.unknown()),
    isInScope: z.boolean().optional(),
    currencyId: zNumericId,
});

export type FilteredEffectCategoryDto = z.infer<typeof zFilteredEffectCategoryDto>;

// Use .catchall to allow arbitrary keys in the object, since z.record().extend() is not possible
// See: https://github.com/colinhacks/zod/discussions/2117#discussioncomment-5157950
const zCalculatedFields = z
    .object({
        unseenChanges: z.boolean().nullable(),
        lastViewedAt: z.date().nullable(),
        myLastViewedAt: z.date().nullable(),
        documents: z.number().nullable(),
        subtasks: z.number().nullable(),
        openSubtasks: z.number().nullable(),
        overdueSubtasks: z.number().nullable(),
        unassignedSubtasks: z.number().nullable(),
        measureViews: z.number().nullable(),
        daysInCurrentLevel: z.number().nullable(),
        commentsCount: z.number().nullable(),
        commentLast: z.date().nullable(),
        currencies: z.array(zNumericId).nullable(),
    })
    .catchall(z.unknown());

export const zMappedFilteredMeasureDto = z.object({
    id: zNumericId,
    title: z.string(),
    clientIid: zNumericId,
    measureConfigId: zNumericId,
    currencyId: zNumericId,
    status: z.nativeEnum(MeasureStatus),
    assignedToId: zNumericId.nullable(),
    completedGateTaskConfigId: zNumericId.nullable(),
    currentGateTaskConfigId: zNumericId,
    createdAt: z.date(),
    updatedAt: z.date(),
    lastModificationAt: z.date(),
    completionDate: z.date().nullable(),
    effectCategories: z.array(zFilteredEffectCategoryDto),
    fields: z.record(z.string(), z.unknown()),
    calculatedFields: zCalculatedFields,
    isFavorite: z.boolean(),
});

export const zFilteredMeasureDto = z.preprocess((obj) => {
    if (obj === null || typeof obj !== "object" || !("completedAt" in obj)) {
        return obj;
    }
    return {
        ...obj,
        completionDate: obj.completedAt,
    };
}, zMappedFilteredMeasureDto);

export type FilteredMeasureDto = z.infer<typeof zFilteredMeasureDto>;

export const zFilteredMeasuresDto = z.object({
    matchingItems: z.number().int().min(0),
    sums: z.record(z.number().optional()),
    measures: z.array(zFilteredMeasureDto),
});

export type FilteredMeasuresDto = z.infer<typeof zFilteredMeasuresDto>;
