import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { zNumericId } from "../../baseschemas";

export const zNumericDisplayIdSelfV1 = extendApi(
    z.object({
        id: zNumericId,
        displayId: zNumericId,
        self: z.string().trim().min(1),
    }),
    {
        title: "Numeric Id And Display Id With Self Link",
        description: "Numeric id, display id and resource self link.",
    },
);

export type NumericDisplayIdSelfDtoV1 = z.infer<typeof zNumericDisplayIdSelfV1>;
