import { z } from "zod";
import { zNumericId } from "./baseschemas";

const zActivityTemplateItem = z.object({
    id: zNumericId,
    titleDe: z.string(),
    descriptionDe: z.string(),
    titleEn: z.string(),
    descriptionEn: z.string(),
    order: z.number(),
});

export type ActivityTemplateItemDto = z.infer<typeof zActivityTemplateItem>;

export const zActivityTemplate = z.object({
    id: zNumericId.optional(), // will be empty if no activity template exists
    items: z.array(zActivityTemplateItem),
    methodId: zNumericId,
});

export type ActivityTemplateDto = z.infer<typeof zActivityTemplate>;
