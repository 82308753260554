import { z } from "zod";
import { AttributeTarget } from "../../constants/AttributeConstants";
import { zNumericId } from "../baseschemas";
import { zNumericIdRequestParam } from "../numericId";

export const zOrderList = z.array(z.number().int()).min(1);
export type OrderList = z.infer<typeof zOrderList>;

export const zCategorizedOrderList = z.array(
    z.object({
        categoryId: zNumericId.nullable(),
        attributeIds: z.array(zNumericId),
    }),
);
export type CategorizedOrderList = z.infer<typeof zCategorizedOrderList>;

export const zAttributeRelation = z.object({
    measureConfigId: z.number(),
    attributeId: z.number(),
    mandatory: z.boolean(),
    order: z.number(),
    target: z.nativeEnum(AttributeTarget),
    isFilled: z.boolean(),
    gateTaskConfigId: zNumericId.nullable(),
    attributeCategoryId: zNumericId.nullish(),
});

export const zAttributeRelationOrder = z.object({
    measureConfigId: z.number(),
    order: z.union([zOrderList, zCategorizedOrderList]),
    target: z.nativeEnum(AttributeTarget),
});

export type AttributeRelation = z.infer<typeof zAttributeRelation>;

const zAttributeRelationIdentifier = z.object({
    measureConfigId: z.number().int(),
    attributeId: z.number().int(),
    target: z.nativeEnum(AttributeTarget),
});

export const zAttributeRelationCreate = zAttributeRelationIdentifier.extend({
    mandatory: z.boolean(),
    gateTaskConfigId: zNumericId.nullable(),
});
export type AttributeRelationCreateDto = z.infer<typeof zAttributeRelationCreate>;

export const zAttributeRelationUpdate = zAttributeRelationIdentifier.extend({
    mandatory: z.boolean(),
    gateTaskConfigId: zNumericId.nullable(),
});
export type AttributeRelationUpdateDto = z.infer<typeof zAttributeRelationUpdate>;

export const zAttributeRelationDelete = zAttributeRelationIdentifier;
export type AttributeRelationDeleteDto = z.infer<typeof zAttributeRelationDelete>;
export type AttributeRelationOrder = z.infer<typeof zAttributeRelationOrder>;

export const zIdeaAttributeRelation = z.object({
    attributeId: z.number(),
    order: z.number(),
    attributeCategoryId: z.number(),
    clientId: z.number(),
    isFilled: z.boolean(),
});
export type IdeaAttributeRelation = z.infer<typeof zIdeaAttributeRelation>;

export const zIdeaAttributeRelationCreate = zIdeaAttributeRelation.omit({
    order: true,
    isFilled: true,
});
export type IdeaAttributeRelationCreateDto = z.infer<typeof zIdeaAttributeRelationCreate>;

export const zIdeaAttributeRelationDelete = zIdeaAttributeRelation.pick({
    attributeId: true,
    clientId: true,
});
export type IdeaAttributeRelationDeleteDto = z.infer<typeof zIdeaAttributeRelationDelete>;

export const zIdeaAttributeRelationOrder = z.object({
    clientId: z.number(),
    // categoryId -> list of relationIds
    order: z.array(
        z.object({
            categoryId: zNumericId,
            attributes: z.array(zNumericId),
        }),
    ),
});
export type IdeaAttributeRelationOrder = z.infer<typeof zIdeaAttributeRelationOrder>;

export const zSuperAdminGetAllAttributesQuery = z.object({
    clientId: z.coerce.number(zNumericId).nullish(),
});

export const zSuperAdminIdeaAttributeRelationParam = zNumericIdRequestParam;
export type SuperAdminIdeaAttributeRelationParam = z.infer<typeof zSuperAdminIdeaAttributeRelationParam>;

export const zSuperAdminChangeAttributeCategoriesOrder = z.object({
    clientId: zNumericId,
    order: z.array(zNumericId),
});
