import { z } from "zod";
import { zNumericId } from "./baseschemas";

export const zExchangeRateDto = z.object({
    id: zNumericId,
    currencyId: zNumericId,
    fiscalYear: z.number(),
    rate: z.number(),
});

export type ExchangeRateDto = z.infer<typeof zExchangeRateDto>;

export const zCurrencyDto = z.object({
    id: zNumericId,
    name: z.string(),
    isoCode: z.string(),
    isDefault: z.boolean(),
    isUsed: z.boolean().optional(),
    exchangeRates: z.array(zExchangeRateDto).optional(),
});

export type CurrencyDto = z.infer<typeof zCurrencyDto>;

export const zCurrencyListDto = z.array(zCurrencyDto);

export type CurrencyListDto = z.infer<typeof zCurrencyListDto>;

const zUpdateExchangeRateDto = z.object({
    fiscalYear: z.number(),
    rate: z.string().nullable(),
});

export type UpdateExchangeRateDto = z.infer<typeof zUpdateExchangeRateDto>;

export const zCreateCurrencyDto = z.object({
    name: z.string(),
    isoCode: z.string(),
    exchangeRates: z.array(zUpdateExchangeRateDto),
});

export type CreateCurrencyDto = z.infer<typeof zCreateCurrencyDto>;

export const zUpdateCurencyDto = z.object({
    name: z.string().optional(),
    isoCode: z.string().optional(),
    exchangeRates: z.array(zUpdateExchangeRateDto).optional(),
});

export type UpdateCurrencyDto = z.infer<typeof zUpdateCurencyDto>;
