import { z } from "zod";
import { AclPermissions, UserTier } from "../../constants";
import { zHexColor } from "../baseschemas";
import { zClientGroupDto } from "../clients";

export const zClientAdminUpdateDto = z.object({
    name: z.string().trim().max(255).optional(),
    internalContactId: z.number().nullable().optional(),
    clientGroups: z.array(zClientGroupDto).optional(),
    defaultUserTier: z.nativeEnum(UserTier).optional(),
    externalUserTier: z.nativeEnum(UserTier).optional(),
    defaultMeasurePermission: z.nativeEnum(AclPermissions).optional(),
    dashboardColors: z.array(zHexColor).min(12).nullable().optional(),
    whiteSpotColor: zHexColor.nullable().optional(),
});

export type ClientAdminUpdateDto = z.infer<typeof zClientAdminUpdateDto>;
