import {
    EffectField,
    EffectFilterCurrencyField,
    EffectFilterDateField,
    EffectValueType,
    GlobalCalculationIdentifierOrLatest,
} from "../constants";

// Map to field names type-safe to pascal case values, so type-safe index-access is possible
const EffectFilterCurrencyFieldMapping = {
    [EffectFilterCurrencyField.Effect]: "Effect",
    [EffectFilterCurrencyField.Initial]: "Initial",
    [EffectFilterCurrencyField.PriceHike]: "PriceHike",
    [EffectFilterCurrencyField.Target]: "Target",
    [EffectFilterDateField.StartDate]: "StartDate",
    [EffectFilterDateField.EndDate]: "EndDate",
    [EffectField.HasInitial]: "HasInitial",
} as const;

const EffectValueTypeMapping = {
    [EffectValueType.Calculated]: "",
    [EffectValueType.Input]: "Input",
} as const;

export function buildEffectKey<
    TCalculationIdentifier extends GlobalCalculationIdentifierOrLatest,
    TField extends keyof typeof EffectFilterCurrencyFieldMapping,
    TInput extends keyof typeof EffectValueTypeMapping = EffectValueType.Calculated,
    TFormula extends "Formula" | "" = "",
>(
    calculationIdentifier: TCalculationIdentifier,
    name: TField,
    input?: TInput,
    formula?: TFormula,
): `${TCalculationIdentifier}${(typeof EffectValueTypeMapping)[TInput]}${(typeof EffectFilterCurrencyFieldMapping)[TField]}${TFormula}` {
    return `${calculationIdentifier}${EffectValueTypeMapping[input ?? EffectValueType.Calculated]}${EffectFilterCurrencyFieldMapping[name]}${formula ?? ""}` as any;
}
