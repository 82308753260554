import { z } from "zod";
import { zMinMaxShortText, zNumericId } from "./baseschemas";

export const zCustomValueDto = z.object({
    id: zNumericId,
    attributeId: zNumericId,
    value: z.string(),
    order: z.number().nullable(),
    deletedAt: z.date().nullable(),
});

export type CustomValueDto = z.infer<typeof zCustomValueDto>;

export const zCustomValueListDto = z.array(zCustomValueDto);

export type CustomValueListDto = z.infer<typeof zCustomValueListDto>;

export const zCreateCustomValueRequestBody = z.object({
    value: zMinMaxShortText,
    measureAttributeId: zNumericId,
});

export type CreateCustomValueRequestBody = z.infer<typeof zCreateCustomValueRequestBody>;
