import {
    ActivityListWidgetConfig,
    BasicMeasureFilterAndScopeConfig,
    CommentStreamWidgetConfig,
    CompletedEffectsWidgetConfig,
    CustomBarChartWidgetConfig,
    FunnelChartWidgetConfig,
    IdeaListWidgetConfig,
    IdeaMatrixWidgetConfig,
    LiveRunUpWidgetConfig,
    ProcessListWidgetConfig,
    ProcessWhiteSpotMatrixWidgetConfig,
    ProjectProgressWidgetConfig,
    RollingForecastWidgetConfig,
    TimelineWidgetConfig,
    zActivityListWidgetConfig,
    zBasicMeasureFilterAndScopeConfig,
    zCommentStreamWidgetConfig,
    zCompletedEffectsWidgetConfig,
    zCustomBarChartWidgetConfig,
    zFunnelChartWidgetConfig,
    zIdeaListWidgetConfig,
    zIdeaMatrixWidgetConfig,
    zLiveRunUpWidgetConfig,
    zProcessListWidgetConfig,
    zProcessWhiteSpotMatrixWidgetConfig,
    zProjectProgressWidgetConfig,
    zRollingForecastWidgetConfig,
    zTimelineWidgetConfig,
    WeeklySavingsRunUpWidgetConfig,
    zWeeklySavingsRunUpWidgetConfig,
    WaterfallWidgetConfig,
    zWaterfallWidgetConfig,
} from "./api";

export function validateProcessListWidgetConfig(config: unknown): config is ProcessListWidgetConfig {
    return zProcessListWidgetConfig.safeParse(config).success;
}

export function validateCompletedEffectsWidgetConfig(config: unknown): config is CompletedEffectsWidgetConfig {
    return zCompletedEffectsWidgetConfig.safeParse(config).success;
}

export function validateActivityListWidgetConfig(config: unknown): config is ActivityListWidgetConfig {
    return zActivityListWidgetConfig.safeParse(config).success;
}

export function validateCustomBarChartWidgetConfig(config: unknown): config is CustomBarChartWidgetConfig {
    return zCustomBarChartWidgetConfig.safeParse(config).success;
}

export function validateFunnelChartWidgetConfig(config: unknown): config is FunnelChartWidgetConfig {
    return zFunnelChartWidgetConfig.safeParse(config).success;
}

export function validateRollingForecastWidgetConfig(config: unknown): config is RollingForecastWidgetConfig {
    return zRollingForecastWidgetConfig.safeParse(config).success;
}

export function validateIdeaListWidgetConfig(config: unknown): config is IdeaListWidgetConfig {
    return zIdeaListWidgetConfig.safeParse(config).success;
}

export function validateIdeaMatrixWidgetConfig(config: unknown): config is IdeaMatrixWidgetConfig {
    return zIdeaMatrixWidgetConfig.safeParse(config).success;
}

export function validateProcessWhiteSpotMatrixWidgetConfig(config: unknown): config is ProcessWhiteSpotMatrixWidgetConfig {
    return zProcessWhiteSpotMatrixWidgetConfig.safeParse(config).success;
}

export function validateCommentStreamWidgetConfig(config: unknown): config is CommentStreamWidgetConfig {
    return zCommentStreamWidgetConfig.safeParse(config).success;
}

export function validateLiveRunUpWidgetConfig(config: unknown): config is LiveRunUpWidgetConfig {
    return zLiveRunUpWidgetConfig.safeParse(config).success;
}

export function validateBasicMeasureFilterAndScopeConfig(config: unknown): config is BasicMeasureFilterAndScopeConfig {
    return zBasicMeasureFilterAndScopeConfig.safeParse(config).success;
}

export function validateTimelineWidgetConfig(config: unknown): config is TimelineWidgetConfig {
    return zTimelineWidgetConfig.safeParse(config).success;
}

export function validateProjectProgressWidgetConfig(config: unknown): config is ProjectProgressWidgetConfig {
    return zProjectProgressWidgetConfig.safeParse(config).success;
}

export function validateWeeklySavingsRunUpWidgetConfig(config: unknown): config is WeeklySavingsRunUpWidgetConfig {
    return zWeeklySavingsRunUpWidgetConfig.safeParse(config).success;
}

export function validateWaterfallWidgetConfig(config: unknown): config is WaterfallWidgetConfig {
    return zWaterfallWidgetConfig.safeParse(config).success;
}
