import { zMaxInt } from "../api";

const MINUTES_PER_HOUR = 60;
const DAYS_PER_WEEK = 5;
const HOURS_PER_DAY = 8;

export const effortParser = (input: string): number => {
    // find all groups of number + unit (wdhm)
    const regexFindings = input.matchAll(/(?<value>[1-9]\d{0,9})(?<unit>[wdhm])/gm);

    let minutesResult = 0;
    for (const entry of regexFindings) {
        const groups = entry.groups;
        if (groups === undefined) {
            continue;
        }
        switch (groups.unit as "w" | "d" | "h" | "m") {
            case "w":
                minutesResult += DAYS_PER_WEEK * HOURS_PER_DAY * MINUTES_PER_HOUR * +groups.value;
                break;
            case "d":
                minutesResult += HOURS_PER_DAY * MINUTES_PER_HOUR * +groups.value;
                break;
            case "h":
                minutesResult += MINUTES_PER_HOUR * +groups.value;
                break;
            case "m":
                minutesResult += +groups.value;
                break;
            default:
                // should never happen on validated input!
                throw new Error("Invalid input string!");
        }
    }
    return minutesResult;
};

export const validateEffortValue = (input: string): boolean => {
    // remove blanks
    const trimmedString = input.replace(/\s/g, "");

    // check that only combinations of number + unit (wdhm) are left
    const reg = new RegExp(/^((?<value>\d+)(?<unit>[wdhm]))*$/gm);
    return reg.test(trimmedString) && zMaxInt.safeParse(effortParser(trimmedString)).success;
};

export const effortConverter = (minutes: number | null): string => {
    let str = "";

    if (minutes == null) {
        return str;
    }

    if (minutes === 0) {
        return "0m";
    }

    let rest = minutes;

    const DAY_MINUTES = MINUTES_PER_HOUR * HOURS_PER_DAY;
    const WEEK_MINUTES = DAY_MINUTES * DAYS_PER_WEEK;

    // weeks
    const flooredWeeks = Math.floor(rest / WEEK_MINUTES);
    if (flooredWeeks > 0) {
        str += `${flooredWeeks}w `;
        rest = rest % WEEK_MINUTES;
    }

    // days
    const flooredDays = Math.floor(rest / DAY_MINUTES);
    if (flooredDays > 0) {
        str += `${flooredDays}d `;
        rest = rest % DAY_MINUTES;
    }

    // hours
    const flooredHours = Math.floor(rest / MINUTES_PER_HOUR);
    if (flooredHours > 0) {
        str += `${flooredHours}h `;
        rest = rest % MINUTES_PER_HOUR;
    }

    // minutes
    if (rest > 0) {
        str += `${rest}m`;
    }

    return str.trim();
};
