import { z } from "zod";
import { Operators } from "../constants";
import { zNumericId, zShortText } from "./baseschemas";

export const zCompareExpression = z.object({
    operator: z.nativeEnum(Operators),
    field: z.string(),
    values: z.array(z.union([z.string(), z.number()])),
});
export type ICompareExpression = z.infer<typeof zCompareExpression>;

export const zFilterDefinition = z.array(zCompareExpression);

export type FilterDefinition = z.infer<typeof zFilterDefinition>;

export const zFilterDto = z.object({
    id: zNumericId,
    sorting: z.number().nullable(),
    name: zShortText,
    definition: zFilterDefinition,
    userId: zNumericId.nullable(),
    clientId: zNumericId.nullable(),
});

export type FilterDto = z.infer<typeof zFilterDto>;

export const zFilterListDto = z.array(zFilterDto);

export type FilterListDto = z.infer<typeof zFilterListDto>;
