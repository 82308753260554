import { z } from "zod";
import { zMinMaxString, zNumericId } from "./baseschemas";
export const zDepartment = z.object({
    id: z.number(),
    nameEn: zMinMaxString,
    nameDe: zMinMaxString,
    createdAt: z.date(),
    isUsed: z.boolean().optional(),
});

export const zDepartmentList = z.array(zDepartment);

export const zCreateDepartmentRequestBody = z.object({
    nameEn: zMinMaxString,
    nameDe: zMinMaxString,
});

export const zUpdateDepartmentRequestBody = z.object({
    nameEn: zMinMaxString.optional(),
    nameDe: zMinMaxString.optional(),
});

export const zUpdateDeleteDepartmentRequestParams = z.object({
    id: zNumericId,
});

export type DepartmentDto = z.infer<typeof zDepartment>;
export type DepartmentListDto = z.infer<typeof zDepartmentList>;
export type CreateDepartmentRequestBody = z.infer<typeof zCreateDepartmentRequestBody>;
export type UpdateDepartmentRequestBody = z.infer<typeof zUpdateDepartmentRequestBody>;
export type UpdateDeleteDepartmentRequestParams = z.infer<typeof zUpdateDeleteDepartmentRequestParams>;
