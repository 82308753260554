import { z } from "zod";
import { IdentityProviderType } from "../constants";
import { zNumericId } from "./baseschemas";

const zIdentityProviderConfigPassword = z.object({
    twoFactorAuthenticationEnforce: z.boolean(),
});

export type IdentityProviderConfigPassword = z.infer<typeof zIdentityProviderConfigPassword>;

const zIdentityProviderConfigSAML = z.object({
    entryPoint: z.string(),
    issuer: z.string(),
    cert: z.string(),
    disableRequestedAuthnContext: z.boolean().optional(), // Optional - passport default: false
    authnContext: z.string().optional(), // Optional - passport default: urn:oasis:names:tc:SAML:2.0:ac:classes:PasswordProtectedTransport
});

export type IdentityProviderConfigSAML = z.infer<typeof zIdentityProviderConfigSAML>;

// Allow only empty config for GitHub Provider
export const zIdentityProviderConfigGitHub = z.object({}).strict();

export type IdentityProviderConfigGitHub = z.infer<typeof zIdentityProviderConfigGitHub>;

const zIdentityProviderConfig = z.union([zIdentityProviderConfigSAML, zIdentityProviderConfigPassword, zIdentityProviderConfigGitHub]);

export type IdentityProviderConfig = z.infer<typeof zIdentityProviderConfig>;

export const zIdentityProviderDto = z.object({
    id: zNumericId,
    name: z.string(),
    description: z.string(),
    isDefault: z.boolean(),
    isEnabled: z.boolean(),
    type: z.nativeEnum(IdentityProviderType),
    config: zIdentityProviderConfig,
    allowSelfRegister: z.boolean(),
    tokenExpirationHours: z.number(),
    isTested: z.boolean(),
    userProfileFirstname: z.string().nullable(),
    userProfileLastname: z.string().nullable(),
    userProfileDepartment: z.string().nullable(),
    userProfilePosition: z.string().nullable(),
    userProfileTelephoneNumber: z.string().nullable(),
    userProfileMobileNumber: z.string().nullable(),
});

export type IdentityProviderDto = z.infer<typeof zIdentityProviderDto>;

export const zIdentityProviderConfigListDto = z.array(zIdentityProviderDto);

export type IdentityProviderConfigListDto = z.infer<typeof zIdentityProviderConfigListDto>;
