import { z } from "zod";
import { zMinMaxShortText, zNumericId } from "./baseschemas";

export const zProjectDto = z.object({
    id: zNumericId,
    clientId: zNumericId,
    name: z.string(),
    createdAt: z.date(),
    updatedAt: z.date(),
    deletedAt: z.date().nullable(),
});

export type ProjectDto = z.infer<typeof zProjectDto>;

export const zGetProjectsResponseDto = z.array(zProjectDto);

export type GetProjectsResponseDto = z.infer<typeof zGetProjectsResponseDto>;

export const zCreateProjectRequestBody = z.object({
    name: zMinMaxShortText,
});

export type CreateProjectRequestBody = z.infer<typeof zCreateProjectRequestBody>;
