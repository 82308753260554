import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";

export const zClientV1 = extendApi(
    z.object({
        name: z.string().trim().min(1),
        timezone: z.string(),
    }),
    {
        title: "Client Response",
        description: "A client.",
    },
);

export type ClientDtoV1 = z.infer<typeof zClientV1>;
