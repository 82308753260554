import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { zMinMaxString, zNumericId } from "../../baseschemas";

export const zEditMeasureV1 = extendApi(
    z.object({
        title: extendApi(zMinMaxString.nullable(), { example: "Make packaging thinner." }),
        assignedTo: zNumericId.nullable().optional(),
    }),
    {
        title: "Edit Measure",
        description: "Edit basic measure data.",
    },
);

export type EditMeasureDtoV1 = z.infer<typeof zEditMeasureV1>;
