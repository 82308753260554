import { PivotRowDto } from "../api";

/**
 * Default data key that is used when there is only one pivot field.
 */
export const SINGLE_PIVOT_DATA_KEY = "value";

export type RechartsData = {
    [x: string]: number | string;
    key: string;
};

export const mapPivotToRecharts = (data: PivotRowDto[], firstPivotField: string, secondPivotField: string | null): RechartsData[] => {
    if (secondPivotField === null) {
        const map = data.reduce(
            (all, { fields, value }) => {
                const first = String(fields[firstPivotField]);

                if (all[first] === undefined) {
                    all[first] = 0;
                }
                all[first] += value;

                return all;
            },
            {} as Record<string, number>,
        );

        return Object.entries(map).map(([key, value]) => ({ key, [SINGLE_PIVOT_DATA_KEY]: value }));
    }

    const map = data.reduce(
        (all, { fields, value }) => {
            const first = String(fields[firstPivotField]);
            const second = String(fields[secondPivotField]);

            if (all[first] === undefined) {
                all[first] = {};
            }
            if (all[first][second] === undefined) {
                all[first][second] = 0;
            }
            all[first][second] += value;

            return all;
        },
        {} as Record<string, Record<string, number>>,
    );

    return Object.entries(map).map(([key, values]) => ({ key, ...values }));
};
