export enum MeasureFieldNamesV1 {
    ASSIGNED_TO = "assignedTo",
    STATUS = "status",
}

export enum MeasureFieldNames {
    ClientIid = "clientIid",
    Title = "title",
    CurrentGateTaskConfigId = "currentGateTaskConfigId",
    MeasureConfigId = "measureConfigId",
    MethodSegment = "methodSegment",
    Status = "status",
    ValueLever = "valueLever",
    AssignedToId = "assignedToId",
    AssignedEditors = "assignedEditors",
    CreatedAt = "createdAt",
    LastModificationAt = "lastModificationAt",
    LastViewedAt = "lastViewedAt",
    MyLastViewedAt = "myLastViewedAt",
    Documents = "documents",
    Subtasks = "subtasks",
    OpenSubtasks = "openSubtasks",
    CompletionDate = "completionDate",
    MeasureViews = "measureViews",
    Visibility = "visibility",
    DaysInCurrentLevel = "daysInCurrentLevel",
    EffectType = "effectType",
    CurrentLevelDuedate = "currentLevelDuedate",
    CurrentLevelAssignedTo = "currentLevelAssignedTo",
    ProcessPulse = "processPulse",
    CurrentDecider = "currentDecider",
    OverdueSubtasks = "overdueSubtasks",
    Favorite = "favorite",
    EstimatedEffort = "estimatedEffort",
    TrackedTime = "trackedTime",
    EstimatedEffortImplementation = "estimatedEffortImplementation",
    TrackedTimeImplementation = "trackedTimeImplementation",
    PlanEfficiency = "planEfficiency",
    ForecastEfficiency = "forecastEfficiency",
    UnassignedSubtasks = "unassignedSubtasks",
    CommentsCount = "commentsCount",
    CommentLast = "commentLast",
    Currencies = "currencies",
    GroupsWithAccess = "groupsWithAccess",
}

// Zod can only use readonly collections for infering types so filter(isSortable) on FIELDS isn't working at compile time
// This list doesn't cover the sortable attributes yet
export const SortableMeasureFields = [
    MeasureFieldNames.ClientIid,
    MeasureFieldNames.Title,
    MeasureFieldNames.CurrentGateTaskConfigId,
    MeasureFieldNames.MeasureConfigId,
    MeasureFieldNames.Status,
    MeasureFieldNames.CreatedAt,
    MeasureFieldNames.LastModificationAt,
    MeasureFieldNames.LastViewedAt,
    MeasureFieldNames.MyLastViewedAt,
    MeasureFieldNames.Documents,
    MeasureFieldNames.Subtasks,
    MeasureFieldNames.OpenSubtasks,
    MeasureFieldNames.CompletionDate,
    MeasureFieldNames.MeasureViews,
    MeasureFieldNames.Visibility,
    MeasureFieldNames.DaysInCurrentLevel,
    MeasureFieldNames.CurrentLevelDuedate,
    MeasureFieldNames.OverdueSubtasks,
    MeasureFieldNames.Favorite,
    MeasureFieldNames.EstimatedEffort,
    MeasureFieldNames.TrackedTime,
    MeasureFieldNames.EstimatedEffortImplementation,
    MeasureFieldNames.TrackedTimeImplementation,
    MeasureFieldNames.PlanEfficiency,
    MeasureFieldNames.ForecastEfficiency,
    MeasureFieldNames.UnassignedSubtasks,
    MeasureFieldNames.CommentsCount,
    MeasureFieldNames.CommentLast,
] as const;

export const MEASURE_GATE_TASK_FIELD_PREFIX = "measure_level";

export enum MeasureGateTaskFieldNames {
    DueDate = "due_date",
    StartDate = "start_date",
    CompletedAt = "completed_at",
}
