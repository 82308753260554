import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { EffectFilterCurrencyField, GlobalCalculationIdentifier, MaxYear, MinYear } from "../../../constants";

export const zDeleteMeasureEffectV1 = extendApi(
    z.object({
        effectCategory: z.record(
            z.string().trim().min(1),
            z.union([
                z.strictObject({ id: z.preprocess((id) => Number(id), z.number()) }),
                z.strictObject({ name: z.string().trim().min(1) }),
            ]),

            // In further versions of the API this could be improved for the user by allowing them to provide a combined name
            // e.g. instead of {name: a}, {name: b}, {id: 4} there could be something like name: "a | b | c" or "a > b > c"
            // This would be the easiest way for non technical use (Excel, ...) - Only the separator must be escaped in the values.
        ),
        currencyId: z.preprocess((id) => (id != null ? Number(id) : undefined), z.number().optional()),
        generation: z.object({ name: z.nativeEnum(GlobalCalculationIdentifier) }),
        month: z.preprocess((y) => Number(y), z.number().min(0).max(11)),
        year: z.preprocess(
            (y) => Number(y),
            z
                .number()
                .min(MinYear)
                .max(MaxYear - 1),
        ),
        type: z.nativeEnum(EffectFilterCurrencyField),
    }),
    {
        title: "Delete Effect",
        description: "Delete an effect on a measure.",
    },
);

export type DeleteMeasureEffectDtoV1 = z.infer<typeof zDeleteMeasureEffectV1>;
