import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { zExchangeRateV1 } from "./exchangeRate";

export const zCurrencyV1 = extendApi(
    z.object({
        name: z.string().min(1).max(255),
        code: z.string().min(1).max(8),
        exchangeRates: z.array(zExchangeRateV1),
    }),
    {
        title: "Currency Response",
        description: "A currency with its exchange rates.",
    },
);

export type CurrencyV1 = z.infer<typeof zCurrencyV1>;
