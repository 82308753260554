export type UserLanguage = "de-DE" | "en-US";

export enum UserStatus {
    STATUS_ACTIVE = 10,
    STATUS_DELETED = 0,
    STATUS_REGISTERED = 1,
    STATUS_INVITED = 2,
    STATUS_INACTIVE = 3,
}

export enum UserTier {
    SuperAdmin = "super_admin",
    Admin = "admin",
    Advanced = "advanced",
    Basic = "basic",
    Light = "light",
}

export const SYSTEM_USER_ID = 1;

export type RememberUserToken = {
    // Unix date (Date.Now) with expiration offset
    expiresAt: number;
    key: string;
};

export enum TokenType {
    NONE = 0,
    STANDARD = 1,
    API = 2,
    SCIM = 3,
}
