import { z } from "zod";
import { MeasureStatus } from "../../constants";
import { zNumericId } from "../baseschemas";
import { zMeasureConfigDto } from "../measure-configs";
import { zFlatMeasureDto } from "../measures";

export const zAdminMeasureDto = zFlatMeasureDto.omit({ gateTasks: true }).extend({
    deletedAt: z.date().nullable(),
});

export type AdminMeasureDto = z.infer<typeof zAdminMeasureDto>;

export const zAdminMeasureListDto = z.array(zAdminMeasureDto);

export type AdminMeasureListDto = z.infer<typeof zAdminMeasureListDto>;

export const zAdminMeasureConfigDto = zMeasureConfigDto.pick({ id: true, name: true });

export type AdminMeasureConfigDto = z.infer<typeof zAdminMeasureConfigDto>;

export const zAdminMeasureConfigListDto = z.array(zAdminMeasureConfigDto);

export type AdminMeasureConfigListDto = z.infer<typeof zAdminMeasureConfigListDto>;

export const zAdminUpdateMeasureRequestParams = z.object({
    measureId: zNumericId,
});

export const zAdminUpdateMeasureRequestBody = z.object({
    status: z.nativeEnum(MeasureStatus).optional(),
    archive: z.boolean().optional(),
    measureConfigId: zNumericId.optional(),
});

export const zAdminBulkUpdateMeasureRequestBody = z.object({
    measureIds: zNumericId.array(),
    status: z.nativeEnum(MeasureStatus).optional(),
    archive: z.boolean().optional(),
    assignedToId: zNumericId.optional(),
});

export const zAdminMeasureConfigValidationDto = z.object({
    measureConfig: zAdminMeasureConfigDto,
    isValidGateTaskConfig: z.boolean(),
    isValidEffectCatgoryConfig: z.boolean(),
    responsibleCanSeeValuestream: z.boolean(),
    removedFields: z.array(z.string()),
    addedFields: z.array(z.string()),
});

export type AdminMeasureConfigValidationDto = z.infer<typeof zAdminMeasureConfigValidationDto>;

export const zAdminMeasureConfigValidationListDto = z.array(zAdminMeasureConfigValidationDto);
export type AdminMeasureConfigValidationListDto = z.infer<typeof zAdminMeasureConfigValidationListDto>;
