import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { zNumericId } from "../../baseschemas";

export const zNumericIdV1 = extendApi(
    z.object({
        id: zNumericId,
    }),
    {
        title: "Id Response",
        description: "Id return value.",
    },
);

export type NumericIdDtoV1 = z.infer<typeof zNumericIdV1>;
