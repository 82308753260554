import { z } from "zod";
import { CommentStatus } from "../constants";
import { zMediumText, zNumericId } from "./baseschemas";

export const zCommentDto = z.object({
    id: z.number().int(),
    comment: z.string(),
    parentId: z.number().int(),
    createdById: z.number().int(),
    createdAt: z.date(),
    updatedById: z.number().int(),
    updatedAt: z.date(),
    status: z.nativeEnum(CommentStatus).nullable(),
});

export const zCommentListDto = z.array(zCommentDto);

export const zCreateCommentRequestBody = z.object({
    comment: zMediumText.min(1),
    parentId: z.number().int(),
});

export const zGetCommentsRequestQuery = z.object({
    parentId: z.preprocess((x) => (x !== undefined ? Number(x) : undefined), z.number().int()),
});

export const zUpdateCommentRequestParams = z.object({
    commentId: zNumericId,
});
export const zUpdateCommentRequestBody = z.object({
    comment: zMediumText.min(1),
});

export const zDeleteCommentRequestParams = z.object({
    commentId: zNumericId,
});

export type CommentDto = z.infer<typeof zCommentDto>;
export type CommentListDto = z.infer<typeof zCommentListDto>;
export type CreateCommentInputDto = z.infer<typeof zCreateCommentRequestBody>;
export type GetCommentsInputDto = z.infer<typeof zGetCommentsRequestQuery>;
export type UpdateCommentInputDto = z.infer<typeof zUpdateCommentRequestParams> & z.infer<typeof zUpdateCommentRequestBody>;
export type DeleteCommentInputDto = z.infer<typeof zDeleteCommentRequestParams>;
