import { z } from "zod";
import { zNumericId } from "../baseschemas";

export const zSuperAdminTranslationRequestQueryParams = z.object({
    clientId: z.coerce.number().pipe(zNumericId).optional(),
});

export const zSuperAdminTranslationDto = z.object({
    id: zNumericId,
    key: z.string(),
    translationDe: z.string(),
    translationEn: z.string(),
    clientId: zNumericId,
});

export type SuperAdminTranslationDto = z.infer<typeof zSuperAdminTranslationDto>;

export const zSuperAdminTranslationListDto = z.array(zSuperAdminTranslationDto);

export type SuperAdminTranslationListDto = z.infer<typeof zSuperAdminTranslationListDto>;
