export enum MeasureStatus {
    STATUS_DISCARDED = 0,
    STATUS_OPEN = 1,
    STATUS_CLOSED = 2,
}

export enum MeasureType {
    TYPE_STANDARD = 1,
    TYPE_POSTCAPTURE = 2,
}

export enum MeasureCalculationGranularity {
    MONTH = "month",
    FISCAL_QUARTER = "fiscal_quarter",
    FISCAL_YEAR = "fiscal_year",
}

export enum CustomFieldsOrderType {
    MEASURE,
    POSTCAPTURE,
    CUSTOMVALUE,
}

export const MeasureCopyOptions = ["measure.attributes", "measure.activities"] as const;
