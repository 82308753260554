import { MAX_FRACTIONAL_DIGITS, MAX_INTEGER_DIGITS } from "../constants";

/**
 * Checks if a value has valid format for a rational number value in our DB.
 */
export function validateRationalNumber(value: number | string, fractionDigitsMax = MAX_FRACTIONAL_DIGITS): boolean {
    // add runtime check for use in JavaScript
    if (typeof value !== "number" && typeof value !== "string") {
        return false;
    }

    // empty values are okay
    if (value.toString().length === 0) {
        return true;
    }

    // strip minus from value
    const strValue = value.toString().replace("-", "");

    // Exponential numbers are too big
    if (strValue.includes("e")) {
        return false;
    }

    // check for decimal separator
    const parts = strValue.split(".");

    switch (parts.length) {
        case 1:
            if (parts[0].length <= MAX_INTEGER_DIGITS) {
                return true;
            }
            break;
        case 2:
            if (parts[0].length <= MAX_INTEGER_DIGITS && parts[1].length <= fractionDigitsMax) {
                return true;
            }
            break;
        default:
            break;
    }

    return false;
}
