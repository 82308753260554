import { z } from "zod";
import { MeasureType } from "../../constants";
import { zNumericId } from "../baseschemas";

const zSuperAdminGateTaskConfig = z.object({
    id: zNumericId,
    name: z.string(),
});

export const zSuperAdminMeasureConfigDto = z.object({
    id: zNumericId,
    clientId: z.number(),
    name: z.string(),
    type: z.nativeEnum(MeasureType),
    gateTaskConfigs: zSuperAdminGateTaskConfig.array(),
    prefillGateAssignee: z.boolean(),
});

export const zSuperAdminGetMeasureConfigQuery = z.object({
    clientId: z.coerce.number(zNumericId).optional(),
});

export const zSuperAdminMeasureConfigUpdateDto = z.object({
    clientId: z.number(),
    prefillGateAssignee: z.boolean(),
});

export type SuperAdminMeasureConfigUpdateDto = z.infer<typeof zSuperAdminMeasureConfigUpdateDto>;

export const zSuperAdminMeasureConfigListDto = z.array(zSuperAdminMeasureConfigDto);

export type SuperAdminMeasureConfigListDto = z.infer<typeof zSuperAdminMeasureConfigListDto>;

export type SuperAdminMeasureConfigDto = z.infer<typeof zSuperAdminMeasureConfigDto>;
