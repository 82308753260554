import { z } from "zod";
import {
    AttributeTable,
    EstimatesValue,
    HistoryEventType,
    IdeaHistoryTable,
    IdeaStatus,
    ReactionType,
    TranslationType,
} from "../constants";
import { zAttributeOptions } from "./attribute-options";
import { zMediumText, zMinMaxString, zNumericId, zShortText } from "./baseschemas";

export const zIdeaAttribute = z.object({
    id: z.number(),
    title: z.string(),
    type: z.string(),
    mandatory: z.boolean().default(false),
    quantity: z.number().nullable(),
    tableName: z.nativeEnum(AttributeTable).nullable(),
    options: zAttributeOptions.nullable(),
    translate: z.nativeEnum(TranslationType).nullable(),
    order: z.number().positive().int().nullable(),
    isCreatable: z.boolean().nullable(),
    categoryId: zNumericId.optional(),
});

export const zIdeaAttributeListDto = z.array(zIdeaAttribute);

const zIdeaFieldValue = z.union([z.string(), z.string().array(), z.number(), z.number().array()]).nullable();

export const zIdeaReaction = z.object({
    ideaId: zNumericId,
    userId: zNumericId,
    type: z.literal(ReactionType.Thumbsup).default(ReactionType.Thumbsup),
});

export const zIdeaReferencedMeasure = z.object({
    id: z.number(),
    displayId: z.number(),
});

export const zIdea = z.object({
    id: z.number(),
    title: z.string(),
    description: zMediumText,
    potentialEstimate: z.nativeEnum(EstimatesValue).nullable(),
    timeEstimate: z.nativeEnum(EstimatesValue).nullable(),
    effortEstimate: z.nativeEnum(EstimatesValue).nullable(),
    ownerId: z.number(),
    createdById: zNumericId,
    createdAt: z.date(),
    lastModificationAt: z.date(),
    fields: z.record(z.string(), zIdeaFieldValue),
    displayId: z.number(),
    reactions: z.array(zIdeaReaction),
    status: z.nativeEnum(IdeaStatus),
    discardReason: z.number().nullable(),
    discardStatement: zMediumText.nullable(),
    discardedAt: z.date().nullable(),
    discardedById: z.number().nullable(),
    referencedMeasure: zIdeaReferencedMeasure.nullable(),
    convertedAt: z.date().nullable(),
    convertedById: z.number().nullable(),
});

export const zIdeaListDto = z.array(zIdea);

export const zIdeaFilterDefintion = z.object({
    potentialEstimate: z.nativeEnum(EstimatesValue).nullable().array().optional(),
    timeEstimate: z.nativeEnum(EstimatesValue).nullable().array().optional(),
    effortEstimate: z.nativeEnum(EstimatesValue).nullable().array().optional(),
    fields: z.record(z.string(), z.string().nullable().array().nullable()).optional(),
    creatorIds: z.array(z.coerce.number().pipe(zNumericId)).optional(),
    ownerIds: z.array(z.coerce.number().pipe(zNumericId)).optional(),
    status: z.nativeEnum(IdeaStatus).array().optional(),
});

export const zIdeaSearchQuery = zIdeaFilterDefintion.extend({
    query: zShortText.optional(),
});

export const zSearchIdeaRequestBody = zIdeaSearchQuery.optional();

export const zCreateIdeaRequestBody = z.object({
    title: zMinMaxString,
    description: zMediumText,
    potentialEstimate: z.nativeEnum(EstimatesValue).nullable().optional(),
    timeEstimate: z.nativeEnum(EstimatesValue).nullable().optional(),
    effortEstimate: z.nativeEnum(EstimatesValue).nullable().optional(),
    fields: z.record(z.string(), zIdeaFieldValue).optional(),
    ownerId: zNumericId.optional(),
});

export const zUpdateIdeaRequestParams = z.object({
    ideaId: zNumericId,
});

export const zUpdateIdeaRequestBody = z.object({
    title: zMinMaxString.optional(),
    description: zMediumText.optional(),
    potentialEstimate: z.nativeEnum(EstimatesValue).nullable().optional(),
    timeEstimate: z.nativeEnum(EstimatesValue).nullable().optional(),
    effortEstimate: z.nativeEnum(EstimatesValue).nullable().optional(),
    fields: z.record(z.string(), zIdeaFieldValue).optional(),
    ownerId: zNumericId.optional(),
});

export const zDiscardIdeaRequestParams = z.object({
    ideaId: zNumericId,
});

export const zDiscardIdeaRequestBody = z.object({
    discardReason: z.number(),
    discardStatement: z.string(),
});

export const zIdeaRequestParams = z.object({
    ideaId: zNumericId,
});

export const zIdeaHistory = z.object({
    previousValue: z.string().nullable(),
    newValue: z.string().nullable(),
    datetime: z.date(),
    operationType: z.nativeEnum(HistoryEventType),
    attribute: z.string(),
    ownerId: z.number().nullable(),
    tableName: z.nativeEnum(IdeaHistoryTable),
});

export const zCreateConvertedIdeaRequestBody = z.object({
    ideaId: z.number(),
    measureConfigId: z.number(),
    assignedToUserId: z.number(),
    description: zMediumText.min(1),
});

export type IdeaAttributeDto = z.infer<typeof zIdeaAttribute>;
export type IdeaAttributeListDto = z.infer<typeof zIdeaAttributeListDto>;
export type IdeaDto = z.infer<typeof zIdea>;
export type IdeaListDto = z.infer<typeof zIdeaListDto>;
export type IdeaSearchQuery = z.infer<typeof zIdeaSearchQuery>;
export type SearchIdeaRequestBody = z.infer<typeof zSearchIdeaRequestBody>;
export type CreateIdeaRequestBody = z.infer<typeof zCreateIdeaRequestBody>;
export type DiscardIdeaRequestParams = z.infer<typeof zDiscardIdeaRequestParams>;
export type DiscardIdeaRequestBody = z.infer<typeof zDiscardIdeaRequestBody>;
export type UpdateIdeaRequestBody = z.infer<typeof zUpdateIdeaRequestBody>;
export type UpdateIdeaRequestParams = z.infer<typeof zUpdateIdeaRequestParams>;
export type UpdateIdeaRequest = UpdateIdeaRequestParams & UpdateIdeaRequestBody;
export type IdeaHistory = z.infer<typeof zIdeaHistory>;
export type IdeaFilterDefintion = z.infer<typeof zIdeaFilterDefintion>;
export type CreateConvertedIdeaRequestBody = z.infer<typeof zCreateConvertedIdeaRequestBody>;
export type IdeaReferencedMeasure = z.infer<typeof zIdeaReferencedMeasure>;
