/**
 * Extract all matching groups from regex.
 * BEWARE: You MUST have /g in the regex otherwise this will be an infinite loop!!!!!!!!!!!!!!!!!!!!!!!!!
 * Can be improved on node >= 12. with matchAll
 * https://stackoverflow.com/questions/432493/how-do-you-access-the-matched-groups-in-a-javascript-regular-expression
 */
export function regexGetMatches(string: string, regex: RegExp, idx: number): string[] {
    if (!regex.flags.includes("g")) {
        throw new SyntaxError("regex must have global flag set!");
    }

    const index = idx || 1; // default to the first capturing group
    const matches = [];

    let match = regex.exec(string);

    while (match != null) {
        matches.push(match[index]);
        match = regex.exec(string);
    }

    return matches;
}
