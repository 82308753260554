/**
 * Helper for filtering arrays to get proper type inference with Array.prototype.filter
 *
 * Example:
 * const list: (A | null)[] = [];
 * const filteredList: list.filter(nonNullable);
 * // filteredList is of type A[]
 */
export function nonNullable<T>(value: T): value is NonNullable<T> {
    return value != null;
}

/**
 * Helper for filtering arrays to get proper type inference with Array.prototype.filter
 * and to assert that a property of the object is non-nullable.
 *
 * Example:
 * const list: { key: A | null }[] = [];
 * const filteredList: A[] = list.filter(nonNullable('key')).map(item => item.key);
 * // filteredList is of type A[] and each element in A[] has { key: {...} }
 */
export function nonNullableKey<T, K extends keyof T>(key: K) {
    return (value: T): value is T & Record<K, NonNullable<T[K]>> => {
        return value[key] != null;
    };
}
