import { z } from "zod";
import { MaxYear, MinYear } from "../constants";
import { zFilterDefinition } from "./filters";

export const zWeeklySavingsRunUpRequestBody = z.object({
    filter: zFilterDefinition,
    year: z.number().int().min(MinYear).max(MaxYear),
    referenceDate: z.string().optional(),
});

export type WeeklySavingsRunUpRequestBody = z.infer<typeof zWeeklySavingsRunUpRequestBody>;

export const zWeeklySavingsRunUpRow = z.object({
    week: z.number().int(),
    year: z.number().int(),
    materializedSavings: z.number().optional(),
    materializedCarryOver: z.number().optional(),
    plannedSavings: z.number().optional(),
    plannedCarryOver: z.number().optional(),
    overdueSavings: z.number().optional(),
    materializedSavingsMeasures: z.array(z.number()),
    materializedCarryOverMeasures: z.array(z.number()),
    plannedSavingsMeasures: z.array(z.number()),
    plannedCarryOverMeasures: z.array(z.number()),
    overdueSavingsMeasures: z.array(z.number()),
});
export type WeeklySavingsRunUpRow = z.infer<typeof zWeeklySavingsRunUpRow>;

export const zWeeklySavingsRunUp = z.array(zWeeklySavingsRunUpRow);
export type WeeklySavingsRunUp = z.infer<typeof zWeeklySavingsRunUp>;
