import { z } from "zod";
import { zNumericId } from "./baseschemas";
import { zFileDto } from "./files";

export const zMethodDetailDto = z.object({
    id: zNumericId,
    code: z.string(),
    nameDe: z.string(),
    nameEn: z.string(),
    hintDe: z.string(),
    hintEn: z.string(),
    valueLeverId: zNumericId,
    numberOfEffectiveMeasures: z.number().optional(),
    effectiveEffect: z.number().optional(),
    totalNumberOfMeasures: z.number().optional(),
    totalEffect: z.number().optional(),
    isFavorite: z.boolean().optional(),
    relevancy: z.number().optional(),
});

export type MethodDetailDto = z.infer<typeof zMethodDetailDto>;

export const zMethodDetailListDto = z.array(zMethodDetailDto);

export type MethodDetailListDto = z.infer<typeof zMethodDetailListDto>;

export const zMethodFileDto = zFileDto.extend({
    clientId: zNumericId.nullable(),
});

export type MethodFileDto = z.infer<typeof zMethodFileDto>;

export const zMethodFileListDto = z.array(zMethodFileDto);

export type MethodFileListDto = z.infer<typeof zMethodFileListDto>;
