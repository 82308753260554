import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";

export const zExchangeRateV1 = extendApi(
    z.object({
        year: z.number(),
        rate: z.number(),
    }),
    {
        title: "Exchange Rate",
        description: "An exchange rate for a currency.",
    },
);

export type ExchangeRateV1 = z.infer<typeof zExchangeRateV1>;
