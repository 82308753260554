import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { zTranslatedString } from "../generics/translatedString";

const zMeasureFieldAllowedValueMeta = z.union([z.array(z.string()), z.array(z.number()), z.array(zTranslatedString)]);

export const zMeasureFieldMetaV1 = extendApi(
    z.object({
        required: z.boolean().default(false),
        name: z.string().trim().min(1), // translated name
        field: z.string().trim().min(1), // internal name
        type: z.enum(["string", "number", "date", "lookup", "lookuplist"]),
        translated: z.boolean().default(false),
        allowedValues: zMeasureFieldAllowedValueMeta.optional(),
        isCreatable: z.boolean().default(false),
    }),
    {
        title: "Field",
        description: "Field definition, types and values.",
    },
);

export const zCreateUpdateMeasureFieldMetaV1 = extendApi(
    z.object({
        fields: z.record(z.string().trim().min(1), zMeasureFieldMetaV1),
    }),
    {
        title: "Update Field Meta",
        description: "Create and update meta information.",
    },
);

export type CreateUpdateMeasureFieldMetaDtoV1 = z.infer<typeof zCreateUpdateMeasureFieldMetaV1>;
export type MeasureFieldAllowedValueMeta = z.infer<typeof zMeasureFieldAllowedValueMeta>;
