import { z } from "zod";
import { zMinMaxShortText, zNumericId } from "./baseschemas";

// Contains only persisted options, client uses some more options additionally, e.g. function for "name"
export const zAttributeCategory = z.object({
    id: zNumericId,
    nameDe: zMinMaxShortText,
    nameEn: zMinMaxShortText,
    order: z.number().int().positive(),
});

export type AttributeCategoryDto = z.infer<typeof zAttributeCategory>;

export const zAttributeCategoryList = z.array(zAttributeCategory);

export type AttributeCategoryListDto = z.infer<typeof zAttributeCategoryList>;

export const zSuperAdminGetAttributeCategoriesQuery = z.object({ clientId: zNumericId });

export const zSuperAdminAttributeCategoryCreate = z.object({
    clientId: zNumericId,
    nameDe: zMinMaxShortText,
    nameEn: zMinMaxShortText,
});

export type SuperAdminAttributeCategoryCreateDto = z.infer<typeof zSuperAdminAttributeCategoryCreate>;

export const zSuperAdminAttributeCategoryUpdate = z.object({
    nameDe: zMinMaxShortText.optional(),
    nameEn: zMinMaxShortText.optional(),
});

export type SuperAdminAttributeCategoryUpdateDto = z.infer<typeof zSuperAdminAttributeCategoryUpdate>;
