export enum SearchViewType {
    DESK = 1,
    GRID = 2,
}

export enum AdditionalInformation {
    NONE = "none",
    TAGS = "tags",
    METHOD = "method",
    PRODUCT_GROUP = "productGroupId",
    COMPANIES = "companies",
}
