import { z } from "zod";
import { zMinMaxShortText, zNumericId } from "./baseschemas";

const zTreeNodeBaseSchema = z.object({
    id: zNumericId,
    parentId: zNumericId.nullable(),
    attributeId: zNumericId,
    nameDe: zMinMaxShortText,
    nameEn: zMinMaxShortText,
    clientId: zNumericId,
    createdAt: z.date(),
    updatedAt: z.date(),
    createdById: zNumericId,
    updatedById: zNumericId,
    order: z.number().int(),
    selectable: z.boolean(),
});

type TreeNode = z.infer<typeof zTreeNodeBaseSchema> & {
    children: TreeNode[];
};

export const zTreeNodeDto: z.ZodType<TreeNode> = zTreeNodeBaseSchema.extend({
    children: z.lazy(() => zTreeNodeDto.array()),
});

export type TreeNodeDto = z.infer<typeof zTreeNodeDto>;

export const zGetTreeNodeRequestQueryParam = z.object({
    attributeId: zNumericId,
});

export type GetTreeNodeRequestQueryParam = z.infer<typeof zGetTreeNodeRequestQueryParam>;

export const zTreeNodeListDto = z.array(zTreeNodeDto);

export type TreeNodeListDto = z.infer<typeof zTreeNodeListDto>;
