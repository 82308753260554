import { z } from "zod";
import { zNumericId } from "./baseschemas";

export const zDomainDto = z.object({
    // This being optional is a limitation of the backend using DomainDto for both input and output
    id: zNumericId.optional(),
    name: z.string(),
    originatorId: zNumericId.nullish(),
    identityProviderId: zNumericId.nullish(),
});

export type DomainDto = z.infer<typeof zDomainDto>;

export const zDomainListDto = z.array(zDomainDto);

export type DomainListDto = z.infer<typeof zDomainListDto>;
