import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";

export const zMeasureConfigV1 = extendApi(
    z.object({
        id: z.number(),
        name: z.string().trim().min(1),
    }),
    {
        title: "Measure Config",
        description: "Type of a measure.",
    },
);

export const zMeasureConfigListV1 = extendApi(z.array(zMeasureConfigV1), {
    title: "Measure Config List",
    description: "List of measure configs.",
});

export type MeasureConfigDtoV1 = z.infer<typeof zMeasureConfigV1>;
export type MeasureConfigListDtoV1 = z.infer<typeof zMeasureConfigListV1>;
