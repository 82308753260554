import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { GlobalCalculationIdentifier } from "../../../constants";
import { zNumericId } from "../../baseschemas";
import { zTranslatedString } from "../generics/translatedString";

export const zCreateUpdateMeasureEffectEffectCategoryMetaV1 = z.object({
    name: z.string().trim().min(1), // translated name
    effectCategory: z.string().trim().min(1), // internal name
    order: zNumericId,
    translated: z.boolean().default(false),
    values: z.array(zTranslatedString),
});

export const zCreateUpdateMeasureEffectGenerationMetaV1 = z.object({
    name: z.nativeEnum(GlobalCalculationIdentifier),
});

export const zCreateUpdateMeasureEffectCurrencyMetaV1 = z.object({
    id: z.number(),
    name: z.string(),
});

export const zCreateUpdateMeasureEffectMetaV1 = extendApi(
    z.object({
        effectCategories: z.record(z.string().trim().min(1), zCreateUpdateMeasureEffectEffectCategoryMetaV1),
        generations: z.array(zCreateUpdateMeasureEffectGenerationMetaV1),
        currencies: z.array(zCreateUpdateMeasureEffectCurrencyMetaV1),
    }),
    {
        title: "Update Effect Meta",
        description: "Create and update effect information.",
    },
);

export type CreateUpdateMeasureEffectMetaDtoV1 = z.infer<typeof zCreateUpdateMeasureEffectMetaV1>;
