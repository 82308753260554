import { z } from "zod";
import { Language, UserLanguage, UserStatus, UserTier } from "../../constants";
import { zNumericId, zShortText } from "../baseschemas";
import { zUserDto } from "../users";

export const zInviteUserRequestBody = z.object({
    realname: zShortText.min(1),
    language: z.union([z.nativeEnum(Language).optional(), z.custom<UserLanguage>()]),
    email: z.string().email(),
    groups: z.number().array(),
    tier: z.nativeEnum(UserTier),
});

export const zUpdateUserRequestParam = z.object({
    userId: zNumericId,
});

export const zUpdateUserRequestBody = z.object({
    email: z.string().email().optional(),
    status: z.nativeEnum(UserStatus).optional(),
    groups: z.number().array().optional(),
    identityProviderId: z.number().nullable().optional(),
    twoFactorAuthenticationEnforce: z.boolean().optional(),
    tier: z.nativeEnum(UserTier).optional(),
});

export type InviteUserRequest = z.infer<typeof zInviteUserRequestBody>;
export type UpdateUserRequestParam = z.infer<typeof zUpdateUserRequestParam>;
export type UpdateUserRequestBody = z.infer<typeof zUpdateUserRequestBody>;
export type UpdateUserRequest = UpdateUserRequestParam & UpdateUserRequestBody;

export const zAdminUserDto = zUserDto.extend({
    twoFactorAuthenticationEnforce: z.boolean(),
    twoFactorAuthenticationValidated: z.boolean(),
});

export type AdminUserDto = z.infer<typeof zAdminUserDto>;

export const zAdminUserListDto = z.array(zAdminUserDto);

export type AdminUserListDto = z.infer<typeof zAdminUserListDto>;

export const zLastLoginDto = z.object({
    userId: zNumericId,
    lastLogin: z.date().nullable(),
});

export const zLastLoginListDto = z.array(zLastLoginDto);

export type LastLoginDto = z.infer<typeof zLastLoginDto>;

export type LastLoginListDto = z.infer<typeof zLastLoginListDto>;
