import { z } from "zod";
import { DashboardNotificationTypes, IdeaNotificationTypes } from "../constants/NotificationConstants";

export function isIdeaNotificationKey(key: string): key is IdeaNotificationTypes {
    return z.nativeEnum(IdeaNotificationTypes).safeParse(key).success;
}

export function isDashboardNotificationKey(key: string): key is DashboardNotificationTypes {
    return z.nativeEnum(DashboardNotificationTypes).safeParse(key).success;
}
