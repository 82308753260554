import { z } from "zod";
import { AdditionalInformation, SearchViewType, Sort } from "../constants";
import { zNumericId } from "./baseschemas";
import { zScopeDto } from "./scope";

export const zSearchConfigDto = z.object({
    id: zNumericId,
    name: z.string(),
    isDefault: z.boolean(),
    userId: zNumericId.nullable(),
    viewType: z.nativeEnum(SearchViewType),
    filterId: zNumericId.nullable(),
    deskOrderBy: z.string(),
    deskSort: z.nativeEnum(Sort),
    gridOrderBy: z.string(),
    gridSort: z.nativeEnum(Sort),
    gridColumns: z.string().array(),
    gridColumnWidths: z.record(z.number()),
    pageSize: z.number(),
    measureConfigDeskId: zNumericId,
    measureConfigGridIds: zNumericId.array(),
    additionalInformation: z.nativeEnum(AdditionalInformation),
    scope: zScopeDto,
    createdAt: z.date(),
    updatedAt: z.date(),
    clientId: zNumericId.nullable(),
});

export const zSearchConfigInputDto = z.object({
    name: z.string().trim(),
    isDefault: z.boolean(),
    userId: zNumericId.nullable(),
    viewType: z.nativeEnum(SearchViewType),
    filterId: zNumericId.nullable(),
    deskOrderBy: z.string().min(1),
    deskSort: z.nativeEnum(Sort),
    gridOrderBy: z.string().min(1),
    gridSort: z.nativeEnum(Sort),
    gridColumns: z.string().array(),
    gridColumnWidths: z.record(z.number()),
    pageSize: z.number().int(),
    measureConfigDeskId: zNumericId,
    measureConfigGridIds: zNumericId.array(),
    additionalInformation: z.nativeEnum(AdditionalInformation),
    scope: zScopeDto,
});

export const zSearchConfigCreateInputDto = zSearchConfigInputDto.extend({
    name: z.string().trim().min(1),
});

export type SearchConfigCreateInputDto = z.infer<typeof zSearchConfigCreateInputDto>;

export const zSearchConfigUpdateParams = z.object({
    id: zNumericId,
});

export type SearchConfigDto = z.infer<typeof zSearchConfigDto>;

export const zSearchConfigListDto = z.array(zSearchConfigDto);

export type SearchConfigListDto = z.infer<typeof zSearchConfigListDto>;
