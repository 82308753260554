import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { zNumericId } from "../../baseschemas";

export const zUpdateMeasureFieldValueV1 = z.union([
    z.string().trim().min(1),
    z.number(),
    z.record(z.union([z.string(), z.number()])),
    z.array(z.record(z.union([z.string(), z.number()]))),
    z.null(),
]);

export const zUpdateMeasureFieldV1 = extendApi(
    z.object({
        name: z.string().trim().min(1),
        value: zUpdateMeasureFieldValueV1,
        assignedTo: zNumericId.nullable().optional(),
    }),
    {
        title: "Update Measure Field",
        description: "Add or update field on a measure.",
    },
);

export type UpdateMeasureFieldDtoV1 = z.infer<typeof zUpdateMeasureFieldV1>;
