import { GateTaskType } from "./constants/GateTaskConstants";

export const DEFAULT_GATE_TRANSLATIONS: Record<string, string[]> = {
    [GateTaskType.Decision]: ["Decision", "Entscheidung"],
    [GateTaskType.Calculation]: ["Calculation", "Berechnung"],
    company_split_calculation: ["Qualification", "Qualifizierung"],
    company_split_implementation: ["Potential Confirmation", "Potenzialbestätigung"],
    post_capture: ["Post-Capture", "Nacherfassung"],
    [GateTaskType.Effectiveness]: ["Confirmation of effectiveness", "Wirksamkeitsbestätigung"],
    [GateTaskType.Activities]: ["Implementation", "Umsetzung"],
};

export const DEFAULT_PROCESS_TRANSLATIONS: Record<string, string[]> = {
    measure: ["Measure", "Maßnahme"],
    post_capture: ["Post-Capture", "Nacherfassung"],
};

export const DEFAULT_DASHBOARD_TRANSLATIONS: Record<string, string[]> = {
    VDLANG_DASHBOARD_DEFAULT_NAME: ["My Dashboard", "Mein Dashboard"],
};
