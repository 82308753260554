import { z } from "zod";
import { AttributeTable, AttributeType, TranslationType } from "../../constants";
import { zAttributeOptions } from "../attribute-options";

export const zAdminAttributeDto = z.object({
    id: z.number(),
    title: z.string(),
    type: z.nativeEnum(AttributeType),
    isMulti: z.boolean().nullable(),
    isCreatable: z.boolean().nullable(),
    tableName: z.nativeEnum(AttributeTable).nullable(),
    translate: z.nativeEnum(TranslationType).nullable(),
    options: zAttributeOptions.nullable(),
});

export type AdminAttributeDto = z.infer<typeof zAdminAttributeDto>;

export const zAdminAttributeListDto = z.array(zAdminAttributeDto);

export type AdminAttributeListDto = z.infer<typeof zAdminAttributeListDto>;
