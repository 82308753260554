import { z } from "zod";
import { AttributeTable, TranslationType } from "../constants";
import { zNumericId } from "./baseschemas";
import { zAttributeOptions } from "./attribute-options";

export const zEffectCategoryAttributeDto = z.object({
    id: zNumericId,
    measureConfigId: zNumericId,
    mandatory: z.boolean(),
    options: zAttributeOptions.nullable(),
    translate: z.nativeEnum(TranslationType),
    order: z.number(),
    isCreatable: z.literal(false).default(false), // EffectCategoryAttribute can not be creatable, but should match the Field type (in client)
    quantity: z.number(),
    tableName: z.nativeEnum(AttributeTable),
    title: z.string(),
    type: z.string(),
});

export type EffectCategoryAttributeDto = z.infer<typeof zEffectCategoryAttributeDto>;
