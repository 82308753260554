import { z } from "zod";
import { DecisionResult, GateStatus } from "../constants";
import { zDateString, zNumericId } from "./baseschemas";

const zDecisionTypes = z.object({
    selfDecision: z.boolean().nullable(),
    recordDecision: z.boolean().nullable(),
    otherDecider: z.boolean().nullable(),
});

export const zGateTaskDto = z.object({
    id: zNumericId,
    measureId: zNumericId,
    clientId: zNumericId.nullable(),
    status: z.nativeEnum(GateStatus),
    // duedate & startDate are database "date" columns, those might come in here as strings
    duedate: zDateString.nullable(),
    startDate: zDateString.nullable(),
    assignedToId: zNumericId.nullable(),
    createdById: zNumericId,
    createdAt: z.date(),
    updatedById: zNumericId,
    updatedAt: z.date(),
    check: z.number().nullable(),
    checkReason: z.string().nullable(),
    completedAt: z.date().nullable(),
    completedById: zNumericId.nullable(),
    validated: z.boolean().optional(),
    remindAt: z.date().nullable(),
    startRemindAt: z.date().nullable(),
    gateTaskConfigId: zNumericId,
    hasSuccessor: z.boolean().optional(),
    decisionTypes: zDecisionTypes.nullable(),
    locked: z.boolean(),
});

export type GateTaskDto = z.infer<typeof zGateTaskDto>;

export const zCompleteGateTaskParams = z.object({
    gateTaskId: zNumericId,
});
export const zCompleteGateTaskRequestBody = z.object({
    decision: z.nativeEnum(DecisionResult).optional(),
});

export type CompleteGateTaskDto = z.infer<typeof zCompleteGateTaskParams> & z.infer<typeof zCompleteGateTaskRequestBody>;
