export enum IdeaNotificationTypes {
    IDEA_UPDATED = "idea_updated",
    IDEA_DISCARDED = "idea_discarded",
    IDEA_CONVERTED = "idea_converted",
    IDEA_SET_RESPONSIBLE = "idea_set_responsible",
    IDEA_COMMENT = "idea_comment",
    IDEA_COMMENT_REPLY = "idea_comment_reply",
}

export enum DashboardNotificationTypes {
    DASHBOARD_SHARED = "dashboard_shared",
}
