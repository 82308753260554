import { z } from "zod";
import { zMinMaxShortText, zNumericId } from "../baseschemas";

export const zCreateTreeNodeDto = z.object({
    attributeId: zNumericId,
    parentId: zNumericId.nullable(),
    nameDe: zMinMaxShortText,
    nameEn: zMinMaxShortText,
});

export type CreateTreeNodeDto = z.infer<typeof zCreateTreeNodeDto>;

export const zUpdateTreeNodeDto = z.object({
    parentId: zNumericId.nullish(),
    nameDe: zMinMaxShortText.optional(),
    nameEn: zMinMaxShortText.optional(),
    selectable: z.boolean().optional(),
});

export type UpdateTreeNodeDto = z.infer<typeof zUpdateTreeNodeDto>;

export const zDeleteTreeNodeDto = z.object({
    replaceValue: zNumericId.nullish(),
});

export type DeleteTreeNodeDto = z.infer<typeof zDeleteTreeNodeDto>;

export const zTreeNodeOrderUpdateDto = z.object({
    id: zNumericId,
    order: z.number().int(),
});

export type TreeNodeOrderUpdateDto = z.infer<typeof zTreeNodeOrderUpdateDto>;

export const zUpdateTreeNodeOrderRequestBody = z.object({
    treeNodes: zTreeNodeOrderUpdateDto.array(),
    attributeId: zNumericId,
});

export type UpdateTreeNodeOrderRequestBody = z.infer<typeof zUpdateTreeNodeOrderRequestBody>;
