import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";

// These errors are related to @/helper/ErrorHandler.ts

export const zErrorDetailsV1 = z.record(z.string().min(1), z.union([z.number(), z.string()]));

export const zErrorV1 = extendApi(
    z.object({
        success: z.literal(false),
        details: zErrorDetailsV1.optional(),
        message: z.string().optional(),
    }),
    {
        title: "Error Response",
        description: "Error messages and details.",
    },
);

export type ErrorDtoV1 = z.infer<typeof zErrorV1>;
