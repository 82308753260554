import { z } from "zod";
import { zIdea } from "../ideas";

export enum ChangeAction {
    REOPEN_OPP = "REOPEN_OPP",
    ARCHIVE_OPP = "ARCHIVE_OPP",
    UNARCHIVE_OPP = "UNARCHIVE_OPP",
}

export const zAdminOpportunityDto = zIdea
    .pick({ id: true, title: true, ownerId: true, status: true, lastModificationAt: true, displayId: true })
    .extend({ deletedAt: z.date().nullable() });

export type AdminOpportunityDto = z.infer<typeof zAdminOpportunityDto>;

export const zAdminOpportunityListDto = z.array(zAdminOpportunityDto);

export type AdminOpportunityListDto = z.infer<typeof zAdminOpportunityListDto>;

export const zOpportunityChangeAction = z.nativeEnum(ChangeAction);
export type OpportunityChangeAction = z.infer<typeof zOpportunityChangeAction>;

export const zAdminUpdateOpportunitiesRequestBody = z.object({
    oppIds: z.number().array(),
    action: zOpportunityChangeAction,
});
export type AdminUpdateOpportunitiesRequestBody = z.infer<typeof zAdminUpdateOpportunitiesRequestBody>;
