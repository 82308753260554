import validator from "validator";
import { z } from "zod";
import { sanitizeDomain, sanitizeNullableNumber } from "../../helper/sanitizer";
import { zNumericId } from "../baseschemas";

export const zCreateOrUpdateDomainRequestBody = z.object({
    clientId: zNumericId.min(1),
    originatorId: zNumericId.nullable().transform(sanitizeNullableNumber),
    name: z
        .string()
        .min(1)
        .refine((v) => validator.isFQDN(v))
        .transform(sanitizeDomain),
});

export const zUpdateDomainRequestParam = z.object({
    id: zNumericId,
});

export type CreateOrUpdateDomainRequestBody = z.infer<typeof zCreateOrUpdateDomainRequestBody>;

export const zSuperAdminDomainDto = z.object({
    id: zNumericId,
    clientId: zNumericId,
    originatorId: zNumericId.nullable(),
    name: z.string(),
});

export type SuperAdminDomainDto = z.infer<typeof zSuperAdminDomainDto>;

export const zSuperAdminDomainListDto = z.array(zSuperAdminDomainDto);

export type SuperAdminDomainListDto = z.infer<typeof zSuperAdminDomainListDto>;
