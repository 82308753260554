import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { MeasureFieldNames, SortableMeasureFields } from "../../../constants";
import { zNumericId } from "../../baseschemas";

export const zSearchV1 = extendApi(
    z.object({
        vql: extendApi(z.string().trim(), {
            title: "Valuedesk Query Language",
            description: "Query data by FIELD OPERATOR VALUE chains.",
            example: "mid = 1",
            /*examples: [
                "clientIid = 1",
                'title LIKE "reduce"' ,
                'clientIid IN [1,2,3,4,5] AND title LIKE "reduce"',
            ],*/ // can't get this to work even { "x": { "summary": "xxx" }}
        }), // Keep empty vql as required for now as at least one required field is required in latest OpenAPI
        filterId: extendApi(zNumericId.optional(), {
            title: "Filter id",
            description: "Use existing filter to select data. This will take precedence over the vql property if both are provided!",
        }),
        startAt: extendApi(
            // https://github.com/colinhacks/zod/issues/425
            z.preprocess((s) => (s == undefined ? 0 : Number(s)), z.number().int().min(0).default(0)),
            { description: "Zero (0) based start of search" },
        ),
        maxResults: z.preprocess((m) => (m == undefined ? 20 : Number(m)), z.number().int().min(1).max(100).default(20)),
        sortBy: z.optional(z.enum(SortableMeasureFields)).default(MeasureFieldNames.ClientIid), // Default for OpenAPI
        sortOrder: z.enum(["asc", "desc"]).default("asc"),
    }),
    {
        title: "Search Measure",
        description: "Search measure query parameter.",
    },
);

export type SearchDtoV1 = z.infer<typeof zSearchV1>;
