import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { zNumericId } from "../../baseschemas";

export const zIdNameV1 = extendApi(
    z.object({
        id: zNumericId,
        name: z.string().trim().min(1),
    }),
    {
        title: "Id And Name Response",
        description: "Id and name return value.",
    },
);

export const zIdNameListV1 = extendApi(z.array(zIdNameV1), {
    title: "Id And Name Response List",
    description: "Id and name list return value.",
});

export type IdNameDtoV1 = z.infer<typeof zIdNameV1>;
export type IdNameListDtoV1 = z.infer<typeof zIdNameListV1>;
