import { z } from "zod";

const zProcessPulseWeekDataDto = z.object({
    week: z.number(),
    activityChanges: z.number(),
    processChanges: z.number(),
});

export type ProcessPulseWeekDataDto = z.infer<typeof zProcessPulseWeekDataDto>;

export const zProcessPulseDto = z.object({
    processId: z.number(),
    items: z.array(zProcessPulseWeekDataDto),
});

export type ProcessPulseDto = z.infer<typeof zProcessPulseDto>;

export const zProcessPulseListDto = z.array(zProcessPulseDto);

export type ProcessPulseListDto = z.infer<typeof zProcessPulseListDto>;
