import { z } from "zod";
import { zShortText } from "./baseschemas";

export const zCompanyDto = z.object({
    id: z.number().int(),
    name: z.string(),
    street: z.string().nullable(),
    city: z.string().nullable(),
    zip: z.string().nullable(),
    country: z.string().nullable(),
    isUsed: z.boolean().optional(),
    createdAt: z.date(),
});

export type CompanyDto = z.infer<typeof zCompanyDto>;

export const zCompanyListDto = z.array(zCompanyDto);

export type CompanyListDto = z.infer<typeof zCompanyListDto>;

export const zCompanyCreateDto = z.object({
    name: zShortText.min(1),
    street: zShortText.optional(),
    city: zShortText.optional(),
    zip: zShortText.optional(),
    country: zShortText.optional(),
});

export type CompanyCreateDto = z.infer<typeof zCompanyCreateDto>;

export const zCompanyUpdateDto = z.object({
    name: zShortText.min(1).optional(),
    street: zShortText.optional(),
    city: zShortText.optional(),
    zip: zShortText.optional(),
    country: zShortText.optional(),
});

export type CompanyUpdateDto = z.infer<typeof zCompanyUpdateDto>;
