import { ErrorEvent } from "@sentry/types";

const DATA_TO_SCRUB = [
    "Authorization",
    "email",
    "realname",
    "displayname",
    "password",
    "name",
    "value",
    "title",
    "description",
    "token",
    "mobileNumber",
    "telephoneNumber",
].map((key) => {
    // Use lowercase for case-insensitive check
    return key.toLowerCase();
});

export function scrubData(event: ErrorEvent): ErrorEvent {
    event.breadcrumbs = recursiveScrubbing(event.breadcrumbs);
    event.extra = recursiveScrubbing(event.extra);
    event.request = recursiveScrubbing(event.request);
    return event;
}

function recursiveScrubbing(data: any, alreadyProcessed = new Set()): any {
    if (data === undefined || alreadyProcessed.has(data)) {
        return data;
    }

    alreadyProcessed.add(data);

    Object.keys(data).forEach((key) => {
        // Use lowercase for case-insensitive check
        if (DATA_TO_SCRUB.includes(key.toLowerCase())) {
            data[key] = "scrubbed";
        } else if (typeof data[key] === "object" && data[key] !== null) {
            data[key] = recursiveScrubbing(data[key], alreadyProcessed);
        } else if (typeof data[key] === "string") {
            data[key] = scrubStringData(data[key], alreadyProcessed);
        }
    });

    return data;
}

function scrubStringData(strData: string, alreadyProcessed: Set<any>): string {
    try {
        const parsed = JSON.parse(strData);
        return JSON.stringify(recursiveScrubbing(parsed, alreadyProcessed));
    } catch (e) {
        return strData;
    }
}
