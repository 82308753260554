import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { zNumericId } from "../../baseschemas";

export const zNumericIdSelfV1 = extendApi(
    z.object({
        id: zNumericId,
        self: z.string().trim().min(1),
    }),
    {
        title: "Numeric Id With Self Link",
        description: "Numeric id data and resource self link.",
    },
);

export type NumericIdSelfDtoV1 = z.infer<typeof zNumericIdSelfV1>;
