import { z } from "zod";
import { IdentityProviderType, TwoFactorAuthenticationStatus } from "../constants";

export const zLoginTypeDto = z.object({
    email: z.string(),
    type: z.nativeEnum(IdentityProviderType),
});

export type LoginTypeDto = z.infer<typeof zLoginTypeDto>;

export const zTokenValidationDto = z.object({
    isValid: z.boolean(),
    displayname: z.string().optional(),
});

export type TokenValidationDto = z.infer<typeof zTokenValidationDto>;

export const zSetPasswordResultDto = z.object({
    success: z.boolean(),
    email: z.string(),
});

export type SetPasswordResultDto = z.infer<typeof zSetPasswordResultDto>;

export const zAuthentication = z.intersection(
    z.object({
        success: z.boolean(),
    }),
    z.discriminatedUnion("twoFactorAuthenticationActivated", [
        z.object({
            // JWT
            token: z.string(),

            rememberKey: z.string().nullable().default(null),

            twoFactorAuthenticationActivated: z.literal(TwoFactorAuthenticationStatus.NoTwoFactorAuthentication),
        }),
        z.object({
            twoFactorAuthenticationActivated: z.literal(TwoFactorAuthenticationStatus.Enabled),
        }),
        z.object({
            twoFactorAuthenticationActivated: z.literal(TwoFactorAuthenticationStatus.SetupRequired),
        }),
    ]),
);

export type AuthenticationDto = z.infer<typeof zAuthentication>;
