import { z } from "zod";
import { zNullishNumericIdQueryParam, zNumericId } from "../baseschemas";

const zMappedSuperAdminCustomValueDto = z.object({
    id: zNumericId,
    measureAttributeId: zNumericId,
    value: z.string(),
    order: z.number().nullable(),
    deletedAt: z.date().nullable(),
    isUsed: z.boolean(),
});

export const zSuperAdminCustomValueDto = z.preprocess((obj) => {
    if (obj === null || typeof obj !== "object" || !("attributeId" in obj)) {
        return obj;
    }
    return {
        ...obj,
        measureAttributeId: obj.attributeId,
    };
}, zMappedSuperAdminCustomValueDto);

export const zGetSuperAdminCustomFieldValuesQuery = z.object({
    clientId: zNullishNumericIdQueryParam,
});

export type SuperAdminCustomValueDto = z.infer<typeof zSuperAdminCustomValueDto>;

export const zSuperAdminCustomValueListDto = z.array(zSuperAdminCustomValueDto);

export type SuperAdminCustomValueListDto = z.infer<typeof zSuperAdminCustomValueListDto>;
