export enum FeedEntryEmbeddedObjectType {
    Measure = "measure",
    Opportunity = "opportunity",
    Activity = "activity",
    UserEvent = "userEvent",
}

export enum FeedEntrySubjectKeys {
    Username = "username",
    MeasureTitle = "measureTitle",
    OpportunityTitle = "opportunityTitle",
    LevelName = "levelName",
    ProcessName = "processName",
}

export enum FeedEntryTranslationKeys {
    MeasureCompleted = "measureCompleted",
    MeasureCreated = "measureCreated",
    MeasureCreatedFromOpportunity = "measureCreatedFromOpportunity",
    MeasureDiscarded = "measureDiscarded",
    MeasureLevelCompleted = "measureLevelCompleted",
    MeasureAssignmentChange = "measureAssignmentChange",
    NewUserAdded = "newUserAdded",
    IdeaCreated = "ideaCreated",
    IdeaDiscarded = "ideaDiscarded",
}
