import { z } from "zod";

export const zAclCondition = z.object({
    fact: z.string(),
    operator: z.enum(["*", "equal", "notEqual", "lessThan", "lessThanInclusive", "greaterThan", "greaterThanInclusive"]),
    value: z.union([z.string(), z.number(), z.boolean()]),
});

export const zAclRule = z.array(zAclCondition);

export type AclCondition = z.infer<typeof zAclCondition>;
export type AclRule = z.infer<typeof zAclRule>;

export enum AclNamespaces {
    Process = 100,
    Idea = 200,
    Valuestream = 300,
    Dashboard = 400,
    Activity = 500,
    Feed = 600,
    Method = 700,
    Api = 800,
    Supplier = 900,
    User = 1000,
    GateTask = 1100,

    Admin = 20000,

    SuperAdmin = 30000,
}

export enum AclPermissions {
    Access = 0,
    Read = 100,
    Create = 200,
    Update = 300,
    Delete = 400,

    Import = 3000,
    Export = 4000,
    FileUpload = 5000,

    Filter = 9000,
    Complete = 9100,
    Decide = 9200,
    SelfDecision = 9201,
    Responsible = 9300,
}

export enum AclCheckResult {
    Deny = -1,
    Skip = 0,
    Allow = 1,
}

// Using unknown would be better but it's not supported by json-rules-engine and BOs and such wouldn't convert to unknown
export type AclFact = Record<string, any>;

export const AclConditionStar: AclCondition = { fact: "*", operator: "*", value: "*" };
export const AclRuleStar: AclRule = [AclConditionStar];

export const AclConditionStarID: AclCondition = { fact: "id", operator: "*", value: "*" };
export const AclRuleStarID: AclRule = [AclConditionStarID];

export enum AclSubjectType {
    User = "user",
    Group = "group",
}
