import { Moment } from "moment";
import { MeasureCalculationGranularity } from "../constants";

export interface FiscalMoment {
    calendarMoment: Moment;
    /**
     * This is a shifted variant of calendarMoment, to account for the beginning of the fiscal year.
     *
     * Be careful when using this for calculations
     *
     * Mainly used for displaying reasons, e.g.:
     * - fiscal year: fiscalMoment.format("YYYY")
     * - fiscal quarter: fiscalMoment.format("Q")
     *
     * @type {Moment}
     * @memberof EffectColumn
     */
    fiscalMoment: Moment;
    fiscalYearStart: number;
}

export function generateFiscalMomentsFromInterval(
    start: Moment | null,
    end: Moment | null,
    granularity: MeasureCalculationGranularity,
    fiscalYearStart: number,
): FiscalMoment[] {
    if (start == null || end == null) {
        return [];
    }

    const effects: FiscalMoment[] = [];

    // map granularity to moment-understandable unit
    const IntervalFromGranularity = {
        [MeasureCalculationGranularity.MONTH]: "month" as const,
        [MeasureCalculationGranularity.FISCAL_QUARTER]: "quarter" as const,
        [MeasureCalculationGranularity.FISCAL_YEAR]: "year" as const,
    };
    const fiscalInterval = IntervalFromGranularity[granularity];

    // get the beginning of the fiscal unit, that contains effectStart
    const currentMoment = start.clone().subtract(fiscalYearStart, "months").startOf(fiscalInterval).add(fiscalYearStart, "months");

    while (currentMoment.isSameOrBefore(end)) {
        effects.push({
            fiscalYearStart,
            calendarMoment: currentMoment.clone(),
            fiscalMoment: currentMoment.clone().subtract(fiscalYearStart, "months"),
        });
        currentMoment.add(1, fiscalInterval);
    }

    return effects;
}

/**
 * Calculate the beginning and end of the fiscal year that the provided date is contained in.
 *
 * @param {Moment} date date for which the fiscal year should be computed
 * @param {number} offset months of year (0-11) in which a fiscal year starts
 *
 * @returns {[Moment, Moment]}
 */

export function getFiscalYearRange(date: Moment, offset: number): [Moment, Moment] {
    const fiscalYearStart = date.clone().startOf("month").subtract(offset, "months").startOf("year").add(offset, "months");
    const fiscalYearEnd = fiscalYearStart.clone().add(1, "year").subtract(1, "day").endOf("month");
    return [fiscalYearStart, fiscalYearEnd];
}
