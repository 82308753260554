import { z } from "zod";
import { zMediumText, zShortText } from "./baseschemas";

export const zSimpleSuggestionsDto = z.object({
    message: z.string().max(2048),
    temperature: z.number().min(0).max(2).optional(),
});

export type SimpleSuggestionsDto = z.infer<typeof zSimpleSuggestionsDto>;

export const zMessageList = z.object({
    message: z.string(),
    messageList: z.array(z.string()).optional(),
});

export type MessageListDto = z.infer<typeof zMessageList>;

export const zMeasureAIExperimentDto = z.object({
    experiment: zShortText,
    title: zShortText,
    description: zMediumText,
});

export type MeasureAIExperimentDto = z.infer<typeof zMeasureAIExperimentDto>;

export const zMeasureAIExperimentListDto = z.array(zMeasureAIExperimentDto);

export type MeasureAIExperimentListDto = z.infer<typeof zMeasureAIExperimentListDto>;
