import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { zMinMaxString, zNumericId } from "../../baseschemas";

export const zCreateMeasureMeasureConfigV1 = z.union([
    z.strictObject({ id: z.number() }),
    z.strictObject({ name: z.string().trim().min(1) }),
]);

export const zCreateMeasureV1 = extendApi(
    z.object({
        title: extendApi(zMinMaxString, { example: "Make packaging thinner." }),
        measureConfig: extendApi(zCreateMeasureMeasureConfigV1, { description: "Retrieve id or name from measure config endpoint." }),
        assignedTo: zNumericId.optional(),
    }),
    {
        title: "Create Measure",
        description: "A create measure schema",
    },
);

export type CreateMeasureDtoV1 = z.infer<typeof zCreateMeasureV1>;
