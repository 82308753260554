import { z } from "zod";
import { FeedEntryEmbeddedObjectType, FeedEntrySubjectKeys, FeedEntryTranslationKeys, ReactionType, VisibilityType } from "../constants";
import { zNumericId } from "./baseschemas";

export const zGetFeedsRequestQuery = z.object({
    page: z.preprocess((x) => (x !== undefined ? Number(x) : undefined), z.number().int().min(1)),
    pageSize: z.preprocess((x) => (x !== undefined ? Number(x) : undefined), z.number().int().min(1)),
    filterId: z.preprocess((x) => (x !== undefined ? Number(x) : undefined), z.number().optional()),
    filterType: z.nativeEnum(FeedEntryEmbeddedObjectType).optional(),
});

export const zGetFeedEntryByIdRequestQuery = z.object({
    feedEntryId: zNumericId,
});

export const zFeedEntrySubject = z.object({
    translationKey: z.nativeEnum(FeedEntryTranslationKeys),
    params: z.record(z.nativeEnum(FeedEntrySubjectKeys), z.string()),
});

export const zFeedEntryEmbeddedObject = z.object({
    id: zNumericId,
    type: z.nativeEnum(FeedEntryEmbeddedObjectType),
});

export const zFeedEntryReaction = z.object({
    feedEntryId: zNumericId,
    userId: zNumericId,
    type: z.nativeEnum(ReactionType),
});

export const zFeedEntryDto = z.object({
    id: zNumericId,
    createdAt: z.date(),
    authorId: zNumericId,
    subject: zFeedEntrySubject,
    visibility: z.nativeEnum(VisibilityType),
    embeddedObject: zFeedEntryEmbeddedObject,
    reactions: z.array(zFeedEntryReaction),
});

export const zFeedsDto = z.object({
    page: z.number().int(),
    totalPages: z.number().int(),
    items: z.array(zFeedEntryDto),
});

export const zPostOrDeleteFeedEntryReactionParam = z.object({
    feedEntryId: zNumericId,
});

export const zPostFeedEntryReactionBody = z.object({
    type: z.nativeEnum(ReactionType),
});

export type GetFeedsRequestQuery = z.infer<typeof zGetFeedsRequestQuery>;
export type GetFeedEntryByIdRequestQuery = z.infer<typeof zGetFeedEntryByIdRequestQuery>;
export type FeedEntrySubject = z.infer<typeof zFeedEntrySubject>;
export type FeedEntryEmbeddedObject = z.infer<typeof zFeedEntryEmbeddedObject>;
export type FeedEntryReaction = z.infer<typeof zFeedEntryReaction>;
export type FeedEntryDto = z.infer<typeof zFeedEntryDto>;
export type FeedsDto = z.infer<typeof zFeedsDto>;
export type PostOrDeleteFeedEntryReactionParam = z.infer<typeof zPostOrDeleteFeedEntryReactionParam>;
export type PostFeedEntryReactionBody = z.infer<typeof zPostFeedEntryReactionBody>;

export const zFeedSummaryDto = z.object({
    clientName: z.string(),
    lastCreatedMeasure: z.date().nullable(),
    lastCreatedMeasureUser: z.date().nullable(),
    lastUpdatedMeasure: z.date().nullable(),
    lastUpdatedMeasureUser: z.date().nullable(),
});

export type FeedSummaryDto = z.infer<typeof zFeedSummaryDto>;
