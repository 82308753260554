import { z } from "zod";

export const zBasicMessageDto = z.discriminatedUnion("success", [
    z.object({
        success: z.literal(true),
        message: z.string().optional(),
    }),
    z.object({
        success: z.literal(false),
        message: z.string(),
    }),
]);

export type BasicMessageDto = z.infer<typeof zBasicMessageDto>;
