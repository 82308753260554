import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { zNumericId } from "../../baseschemas";

export const zUserV1 = extendApi(
    z.object({
        id: zNumericId,
        email: z.string().email(),
        status: z.string(),
        realname: z.string().nullable(),
        displayname: z.string().nullable(),
        telephoneNumber: z.string().nullable(),
        mobileNumber: z.string().nullable(),
        department: z.string().nullable(),
        position: z.string().nullable(),
        timezone: z.string().nullable(),
        imageHash: z.string().nullable().optional(),
    }),
    {
        title: "User",
        description: "User Information.",
    },
);

export const zUserListV1 = extendApi(z.array(zUserV1), {
    title: "User List",
    description: "List of users.",
});

export type UserDtoV1 = z.infer<typeof zUserV1>;
export type UserListDtoV1 = z.infer<typeof zUserListV1>;
