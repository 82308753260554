import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { EstimatesValue, IdeaStatus } from "../../../constants/IdeaConstants";
import { getEnumKeyByEnumValue } from "../../../helper/getEnumKeyByEnumValue";
import { zNumericId } from "../../baseschemas";

function estimatesValueToEnum(val: unknown): number | null {
    if (val === null) {
        return null;
    }
    if (isFinite(val as number)) {
        return Number(val);
    } else {
        return Number(getEnumKeyByEnumValue(EstimatesValue, val));
    }
}

export const zEstimatesValue = z.preprocess((val) => estimatesValueToEnum(val), z.nativeEnum(EstimatesValue).nullable());

export const zOpportunityV1 = extendApi(
    z.object({
        id: zNumericId,
        displayId: zNumericId,
        title: z.string().trim().min(1),
        description: z.string(),
        potentialEstimate: z.preprocess((s) => (typeof s === "number" ? EstimatesValue[s] : s), z.string().nullable()),
        timeEstimate: z.preprocess((s) => (typeof s === "number" ? EstimatesValue[s] : s), z.string().nullable()),
        effortEstimate: z.preprocess((s) => (typeof s === "number" ? EstimatesValue[s] : s), z.string().nullable()),
        status: z.nativeEnum(IdeaStatus),
    }),
    {
        title: "Opportunity Response",
        description: "An opportunity.",
    },
);

export const zOpportunityListV1 = extendApi(z.array(zOpportunityV1), {
    title: "Opportunity Response List",
    description: "Opportunity list return value.",
});

export type OpportunityDtoV1 = z.infer<typeof zOpportunityV1>;
export type OpportunityListDtoV1 = z.infer<typeof zOpportunityListV1>;
