import { z } from "zod";
import { MaxYear, MinYear } from "../constants";
import { zCompareExpression, zFilterDefinition } from "./filters";
import { zScopeDto } from "./scope";

export const zProjectProgressMonth = z.object({
    month: z.number().int().min(0).max(11),
    year: z.number().int().min(MinYear).max(MaxYear),
});

export type ProjectProgressMonth = z.infer<typeof zProjectProgressMonth>;

const zProjectProgressScope = zScopeDto.omit({ startDate: true, endDate: true });

export type ProjectProgressScope = z.infer<typeof zProjectProgressScope>;

export const zProjectProgressRequestBody = z.object({
    filter: zFilterDefinition,
    start: zProjectProgressMonth,
    end: zProjectProgressMonth,
    scope: zProjectProgressScope,
    oneTimeFieldValues: zCompareExpression,
    recurringFieldValues: zCompareExpression,
});

export type ProjectProgressRequestBody = z.infer<typeof zProjectProgressRequestBody>;

const zProjectProgressRow = z.object({
    value: z.number(),
    timeRange: zProjectProgressMonth,
    stackProperty: z.string().min(1),
    stackValue: z.string().or(z.number()),
});
export type ProjectProgressRow = z.infer<typeof zProjectProgressRow>;

export const zProjectProgress = z.array(zProjectProgressRow);
export type ProjectProgressDto = z.infer<typeof zProjectProgress>;
