import { z } from "zod";
import { CalculationHistoryType, HistoryEventType } from "../constants";
import { zNumericId } from "./baseschemas";

export const zHistoryEntryDto = z.object({
    historyType: z.nativeEnum(CalculationHistoryType),
    operationType: z.nativeEnum(HistoryEventType),
    previousValue: z.string().or(z.number()).nullable(),
    newValue: z.string().or(z.number()).nullable(),
    updatedAt: z.date(),
    updatedById: zNumericId,
});

export type HistoryEntryDto = z.infer<typeof zHistoryEntryDto>;

export const zEffectCategoryHistoryEntryDto = zHistoryEntryDto.extend({
    historyType: z.literal(CalculationHistoryType.EffectCategory),
    effectCategoryId: zNumericId,
    attribute: z.string(),
    previousValue: z.string().nullable(),
    newValue: z.string(),
});

export type EffectCategoryHistoryEntryDto = z.infer<typeof zEffectCategoryHistoryEntryDto>;

export const zCalculationHistoryEntryDto = zHistoryEntryDto.extend({
    historyType: z.literal(CalculationHistoryType.Calculation),
    effectCategoryId: zNumericId,
    attribute: z.string(),
    effectStartDate: z.string().nullable(),
    effectEndDate: z.string().nullable(),
    calculationIdentifier: z.string(),
    previousValue: z.string().nullable(),
    newValue: z.string().nullable(),
});

export type CalculationHistoryEntryDto = z.infer<typeof zCalculationHistoryEntryDto>;

export const zEffectCategoryHistoryDto = z.object({
    items: z.array(z.discriminatedUnion("historyType", [zEffectCategoryHistoryEntryDto, zCalculationHistoryEntryDto])),
});

export type EffectCategoryHistoryDto = z.infer<typeof zEffectCategoryHistoryDto>;
