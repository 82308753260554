export const DiscardReasons: Array<{ id: number; en: string; de: string }> = [
    {
        id: 1,
        en: "Will be transferred to another place",
        de: "Wird an eine andere Stelle überführt",
    },
    {
        id: 2,
        en: "Technically not feasible",
        de: "Technisch nicht umsetzbar",
    },
    {
        id: 3,
        en: "Changed circumstances",
        de: "Veränderte Umstände",
    },
    {
        id: 4,
        en: "Changes in demand",
        de: "Bedarfsveränderungen",
    },
    {
        id: 5,
        en: "Changes in exchange rates",
        de: "Währungskursentwicklung",
    },
    {
        id: 6,
        en: "Changes in standards",
        de: "Normänderungen",
    },
    {
        id: 7,
        en: "Others",
        de: "Andere",
    },
    {
        id: 8,
        en: "Negative decision of the decision maker",
        de: "Bestimmung der entscheidungsbefugten Person(en)",
    },
    {
        id: 9,
        en: "The effort does not justify the execution",
        de: "Aufwand rechtfertigt die Umsetzung nicht",
    },
    {
        id: 10,
        en: "Risk assessment does not justify the execution",
        de: "Durch Risikoabwägung nicht gerechtfertigt",
    },
    {
        id: 11,
        en: "Lack of resources for implementation",
        de: "Fehlende Ressourcen zur Umsetzung",
    },
    {
        id: 12,
        en: "Already exists",
        de: "Bereits vorhanden",
    },
    {
        id: 13,
        en: "Decision by department",
        de: "Entscheidung durch Fachabteilung",
    },
    {
        id: 14,
        en: "Remaining term too short",
        de: "Restlaufzeit zu gering",
    },
];
