export enum GateStatus {
    STATUS_OPEN = 1000,
    STATUS_COMPLETED = 1002,
    STATUS_DISCARDED = 1003,
    STATUS_SKIPPED = 1004,
}

export enum GateType {
    GATE_IDEA = 10,
    GATE_CALCULATION = 20,
    GATE_DECISION = 40,
    GATE_IMPLEMENTATION = 50,
    GATE_POST_CAPTURE = 60,
    GATE_EFFECTIVENESS = 9999,
}

// Keep in sync with GateType, Typescript does not allow extending enums
export enum CurrentGateType {
    GATE_IDEA = 10,
    GATE_CALCULATION = 20,
    GATE_DECISION = 40,
    GATE_IMPLEMENTATION = 50,
    GATE_POST_CAPTURE = 60,
    GATE_EFFECTIVENESS = 9999,
    GATE_CLOSED = 9007199254740991, // = Number.MAX_SAFE_INTEGER, cannot be an expression when used as index of objects
}

export enum GateTaskType {
    Calculation = "calculation",
    Decision = "decision",
    Activities = "activities",
    Effectiveness = "effectiveness",
}
