import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { zEstimatesValue } from "./opportunity";

export const zCreateOpportunityV1 = extendApi(
    z.object({
        title: z.string().trim().min(1),
        description: z.string(),
        potentialEstimate: zEstimatesValue,
        timeEstimate: zEstimatesValue,
        effortEstimate: zEstimatesValue,
    }),
    {
        title: "Create Opportunity",
        description: "A create opportunity schema",
    },
);

export type CreateOpportunityDtoV1 = z.infer<typeof zCreateOpportunityV1>;
