import { z } from "zod";
import { DecisionType } from "../constants";
import { zNumericId } from "./baseschemas";

export const zDecisionDto = z.object({
    id: zNumericId,
    gateTaskId: zNumericId,
    selection: z.nativeEnum(DecisionType),
    requestedAt: z.date().nullable(),
    requestedDeciderId: zNumericId.nullable(),
    requesterId: zNumericId.nullable(),
    reason: z.string().nullable(),
    isApproved: z.boolean(),
    deciderId: zNumericId.nullable(),
});

export type DecisionDto = z.infer<typeof zDecisionDto>;

export const zDecisionListDto = z.array(zDecisionDto);

export type DecisionListDto = z.infer<typeof zDecisionListDto>;
