import { z } from "zod";
import { zNumericId } from "./baseschemas";

export const zValueLeverDto = z.object({
    id: zNumericId,
    nameDe: z.string(),
    alias: z.enum(["contract", "makeorbuy", "market", "price", "process", "product", "quantity", "waste", "custom"]),
    code: z.string(),
    order: z.number(),
    segment: z.number(),
    nameEn: z.string().nullable(),
    createdAt: z.date(),
    updatedAt: z.date(),
});

export type ValueLeverDto = z.infer<typeof zValueLeverDto>;

export const zValueLeverListDto = z.array(zValueLeverDto);

export type ValueLeverListDto = z.infer<typeof zValueLeverListDto>;
