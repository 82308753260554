export enum SubTaskStatus {
    STATUS_REMOVED = 0,
    STATUS_OPEN = 1,
    STATUS_COMPLETED = 2,
}

export enum SubTaskStatusFilter {
    ALL = 100,
    OPEN = 101,
    COMPLETED = 102,
}

export enum SubTaskOrderBy {
    DUEDATE = "duedate",
    ASSIGNED_TO = "assignedTo",
    CREATED_AT = "createdAt",
    TITLE = "title",
    PRIORITY = "priority",
}

export enum SubTaskGroupBy {
    MEASURE = "measure",
    CREATED_BY = "createdBy",
    ASSIGNED_TO = "assignedTo",
    DUEDATE = "duedate",
    PRIORITY = "priority",
}

export enum SubTaskGroupOrderBy {
    EFFECT = "effect",
    ID = "id",
    TITLE = "title",
    DAYS_UNTIL_EFFECTIVENESS = "daysUntilEffectiveness",
    NAME = "name",
    DUEDATE = "duedate",
    PRIORITY = "priority",
}

export enum SubTaskPriority {
    PRIO_LOW = -1,
    PRIO_MEDIUM = 0,
    PRIO_HIGH = 1,
}
