import { z } from "zod";
import { zNumericId } from "./baseschemas";

export const zCostLeverDto = z.object({
    id: zNumericId,
    code: z.string(),
    nameDe: z.string(),
    nameEn: z.string(),
    hintDe: z.string(),
    hintEn: z.string(),
    clientId: zNumericId.nullable(),
    valueLeverId: zNumericId,
});

export type CostLeverDto = z.infer<typeof zCostLeverDto>;

export const zCostLeverListDto = z.array(zCostLeverDto);

export type CostLeverListDto = z.infer<typeof zCostLeverListDto>;
