import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { zEstimatesValue } from "./opportunity";

export const zEditOpportunityV1 = extendApi(
    z.object({
        title: z.string().trim().min(1),
        description: z.string(),
        potentialEstimate: zEstimatesValue,
        timeEstimate: zEstimatesValue,
        effortEstimate: zEstimatesValue,
    }),
    {
        title: "Edit Opportunity",
        description: "An edit opportunity schema",
    },
);

export type EditOpportunityDtoV1 = z.infer<typeof zEditOpportunityV1>;
