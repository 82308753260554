import { z } from "zod";
import {
    EstimatesValue,
    IdeaSortBy,
    IdeaStatus,
    IdeaXAxis,
    MethodMeasureTimerange,
    MethodViewType,
    OpportunityTab,
    Sort,
    SubTaskGroupBy,
    SubTaskGroupOrderBy,
    SubTaskOrderBy,
    SubTaskStatusFilter,
} from "../constants";
import { zNumericId } from "./baseschemas";

export const zUiState = z.object({
    activityStatus: z.nativeEnum(SubTaskStatusFilter),
    activityOrderBy: z.nativeEnum(SubTaskOrderBy),
    activitySort: z.nativeEnum(Sort),
    activityGrouping: z.boolean(),
    activityGroupBy: z.nativeEnum(SubTaskGroupBy),
    activityGroupOrderBy: z.nativeEnum(SubTaskGroupOrderBy),
    activityGroupSort: z.nativeEnum(Sort),
    activityFilterId: zNumericId.nullable(),
    activityTab: z.boolean(),
    methodTableOrderBy: z.string(),
    methodTableSort: z.nativeEnum(Sort),
    methodViewType: z.nativeEnum(MethodViewType),
    methodMeasureTimerange: z.nativeEnum(MethodMeasureTimerange),
    lastSearchConfigId: z.number(),
    searchConfigOrder: z.number().array(),
    lastDashboardId: z.number().nullable(),
    opportunitiesGridOrderBy: z.nativeEnum(IdeaSortBy),
    opportunitiesTableOrderBy: z.string(), // to handle dynamic/customer-specific fields
    opportunitiesGridSort: z.nativeEnum(Sort),
    opportunitiesTableSort: z.nativeEnum(Sort),
    opportunitiesTab: z.nativeEnum(OpportunityTab),
    opportunitiesMatrixXAxis: z.nativeEnum(IdeaXAxis),
    activityAssignedToIds: z.number().array(),
    activityCreatedByIds: z.number().array(),
    ideaSearchPotentialEstimate: z.nativeEnum(EstimatesValue).nullable().array(),
    ideaSearchTimeEstimate: z.nativeEnum(EstimatesValue).nullable().array(),
    ideaSearchEffortEstimate: z.nativeEnum(EstimatesValue).nullable().array(),
    ideaSearchFields: z.record(z.string(), z.string().nullable().array().nullable()),
    ideaSearchCreatedByIds: z.number().array(),
    ideaSearchOwnedByIds: zNumericId.array(),
    ideaSearchStatus: z.nativeEnum(IdeaStatus).array(),
    ideaTablePageSize: z.number().int(),
    ideaTableColumns: z.string().array(),
    ideaAttributeCategoryState: z.record(zNumericId, z.boolean()),
    measureAttributeCategoryState: z.record(zNumericId, z.boolean()),
});

export type UiStateDto = z.infer<typeof zUiState>;
export type UiStateInput = z.infer<typeof zUiState>;
