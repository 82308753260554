export enum FieldTypes {
    Integer = "INTEGER",
    Double = "DOUBLE",
    Text = "TEXT",
    Single = "SINGLE",
    Currency = "CURRENCY",
    Date = "DATE",
    Set = "SET",
    User = "USER",
    Users = "USERS",
    Boolean = "BOOLEAN",
    TimeEstimate = "TIME_ESTIMATE",
    Pulse = "PULSE",
}
