export enum Language {
    EN = "en",
    DE = "de",
}

// All locales supported by MS Excel
export const EXPORT_LOCALES = [
    "ar",
    "zh-hans",
    "zh-hant",
    "cs",
    "da",
    "en-gb",
    "en-us",
    "fi",
    "fr-ca",
    "fr-fr",
    "de",
    "el",
    "he",
    "hu",
    "id",
    "it",
    "ja",
    "ko",
    "no",
    "pl",
    "pt-pt",
    "pt-br",
    "ru",
    "sk",
    "es-mx",
    "es-es",
    "sv",
    "th",
    "tr",
] as const;

export type ExportLocale = (typeof EXPORT_LOCALES)[number];
