export const DEFAULT_DASHBOARD_COLORS = [
    "#264653",
    "#2a9d8f",
    "#5c6bc0",
    "#e9c46a",
    "#f4a261",
    "#e76f51",
    "#5aaad8",
    "#955eb7",
    "#df76c8",
    "#70d6cf",
    "#80c97e",
    "#c37f73",
];
export const DEFAULT_WHITE_SPOT_COLOR = "#f4a261";
