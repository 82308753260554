import { z } from "zod";

export const zSnapshotQuery = z.object({
    token: z.string().trim().min(1),
    date: z
        .string()
        .refine((val) => val.match(/^\d{4}-\d{2}-\d{2}$/) != null, {
            message: "String must be date with YYYY-MM-DD format",
        })
        .optional(),
});
