import { camelCase } from "lodash";
import moment from "moment";
import { UserStatus } from "../constants/UserConstants";

export const UNKNOWN_USER = "Unknown User";

/**
 * Format a date object that will be sent to api.
 *
 * Returns date in format YYYY-MM-DD
 */
export function formatDateForAPI(date: moment.Moment | Date | string): string;
export function formatDateForAPI(date: moment.Moment | Date | string | null): string | null;
export function formatDateForAPI(date?: moment.Moment | Date | string | null): string | undefined | null {
    if (date === null) {
        return null;
    }

    if (date === undefined) {
        return undefined;
    }

    if (moment.isMoment(date)) {
        return date.format("YYYY-MM-DD");
    }

    if (date instanceof Date) {
        return date.toISOString().slice(0, 10);
    }

    return date.slice(0, 10);
}

export function splitIsoDateString(date: string): number[] {
    return date.split("-").map(Number);
}

export interface IFormatUserOptions {
    translate?: TranslateFunction;
    withAdornment?: boolean;
    warnOnMissingUser?: boolean;
}

class FormatUserOptions implements IFormatUserOptions {
    constructor(
        public translate?: TranslateFunction,
        public withAdornment = true,
    ) {}
}

// Use minimal type to avoid pulling in DTOs/entities/...
export interface FormatableUser {
    displayname: string | null;
    status: UserStatus;
}

export function formatUserName(user: FormatableUser | null, options?: IFormatUserOptions): string {
    const defaultOptions = new FormatUserOptions();
    const { translate } = { ...defaultOptions, ...options };

    if (user == null) {
        return typeof translate === "function" ? translate(UNKNOWN_USER) : UNKNOWN_USER;
    }

    const { displayname } = user;
    let name = `${displayname ?? ""}`.trim();
    if (user.status === UserStatus.STATUS_DELETED) {
        name = "deleted_user";
        return typeof translate === "function" ? translate(name) : name;
    }
    return name;
}

export type TranslateFunction = (value: string | string[]) => string;

/**
 * Capitalize the first letter of the string
 * Examples:
 * - "test" -> "Test"
 * - "Test" -> "Test"
 */
export function capitalize(input: string): string {
    return input.charAt(0).toUpperCase() + input.slice(1);
}

/**
 * Merge some strings in a camelized way.
 * "hallo", "das", "ist" -> "halloDasIst"
 */
export function mergeCamelized(...inputs: (string | null | undefined)[]): string {
    return camelCase(inputs.filter((input) => input != null && input !== "").join("_"));
}

/**
 * Remove a prefix from the beginning of a string and make first character of the result lowercase, e.g.
 * removePrefix(planInitial, plan) => initial
 */
export function removePrefix(input: string, prefix: string | null): string {
    if (prefix == null) {
        return input;
    }
    return input.replace(new RegExp(`^${prefix}\\w`), (match: string) => match[match.length - 1].toLowerCase());
}
