import { extendApi } from "@anatine/zod-openapi";
import { z } from "zod";
import { zNumericId } from "../../baseschemas";

export const zTranslatedString = z.object({
    id: zNumericId,
    name: z.string(),
    nameTranslations: extendApi(
        z
            .object({
                en: z.string(),
                de: z.string(),
            })
            .optional(),
        {
            title: "Name translations",
            description: "Translated values for providing name property in different languages.",
        },
    ),
});

export type TranslatedString = z.infer<typeof zTranslatedString>;
