import { z } from "zod";
import { zNumericId } from "../baseschemas";

export const zSuperAdminCostLeverDto = z.object({
    id: zNumericId,
    valueLeverId: zNumericId,
    clientId: zNumericId.nullable(),
    code: z.string(),
    nameDe: z.string(),
    nameEn: z.string(),
    hintDe: z.string(),
    hintEn: z.string(),
    keywordsDe: z.string(),
    keywordsEn: z.string(),
});

export type SuperAdminCostLeverDto = z.infer<typeof zSuperAdminCostLeverDto>;

export const zSuperAdminCostLeverListDto = z.array(zSuperAdminCostLeverDto);

export type SuperAdminCostLeverListDto = z.infer<typeof zSuperAdminCostLeverListDto>;
