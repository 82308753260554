export enum MethodSegment {
    CUSTOM = 1,
    PURCHASING = 2,
    PRODUCT = 3,
}

export enum MethodTableColumn {
    CODE = "CODE",
    NAME = "NAME",
    FAVORITE = "FAVORITE",
    EFFECT = "EFFECT",
    NUMBER_OF_MEASURES = "NUMBER_OF_MEASURES",
    AVERAGE_EFFECT = "AVERAGE_EFFECT",
    RELEVANCY = "RELEVANCY",
}

export enum MethodViewType {
    LIST,
    TILE,
}

export enum MethodMeasureTimerange {
    CURRENT_FISCAL_YEAR,
    LAST_FISCAL_YEAR,
    LAST_12_MONTHS,
    ALL_MEASURES,
}
