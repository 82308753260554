import { z } from "zod";
import { GateTaskType, GlobalCalculationIdentifier, MeasureType } from "../constants";
import { zNumericId } from "./baseschemas";
import { zEffectCategoryAttributeDto } from "./effect-category-attributes";

const zDecisionRules = z.object({
    selfDecision: z.array(z.any()),
    recordDecision: z.array(z.any()),
});

export type DecisionRules = z.infer<typeof zDecisionRules>;

export const zGateTaskConfigDto = z.object({
    id: zNumericId,
    name: z.string(),
    type: z.nativeEnum(GateTaskType),
    calculationIdentifier: z.nativeEnum(GlobalCalculationIdentifier).nullable(),
    order: z.number(),
    decisionRules: zDecisionRules.nullable(),
    processName: z.string().optional(),
    hintKey: z.string().nullable(),
    mandatoryAttributeIds: z.number().array().optional(),
    lockPrevAndCurrentCalculations: z.boolean(),
});

export type GateTaskConfigDto = z.infer<typeof zGateTaskConfigDto>;

const zMeasureConfigMeasureAttributeDto = z.object({
    id: zNumericId,
    attributeId: zNumericId,
    measureConfigId: zNumericId,
    updatedAt: z.date(),
    createdAt: z.date(),
    mandatory: z.boolean(),
    order: z.number().nullable(),
    gateTaskConfigId: zNumericId.nullable(),
    isVisible: z.boolean(),
    title: z.string(),
});

export const zMeasureConfigDto = z.object({
    id: zNumericId,
    name: z.string(),
    type: z.nativeEnum(MeasureType),
    createdById: zNumericId,
    createdAt: z.date(),
    updatedById: zNumericId,
    updatedAt: z.date(),
    currencyId: zNumericId.nullable(),
    gateTaskConfigs: z.array(zGateTaskConfigDto),
    effectCategoryAttributes: z.array(zEffectCategoryAttributeDto),
    measureAttributes: z.array(zMeasureConfigMeasureAttributeDto),
});

export type MeasureConfigDto = z.infer<typeof zMeasureConfigDto>;

export const zGetMeasureConfigsResponseDto = z.array(zMeasureConfigDto);

export type GetMeasureConfigsResponseDto = z.infer<typeof zGetMeasureConfigsResponseDto>;
