import { isString } from "lodash";
import isDecimal from "validator/lib/isDecimal";
import isFloat from "validator/lib/isFloat";
import { evaluateCurrencyFormula } from "./math";
import { validateCurrencyValue } from "./validateCurrencyValue";

const NEGATED_FORMULA_REGEX = /^=-\((.+)\)$/;

/**
 * Round value to a currency with four decimal places.
 */
export function roundCurrency(value: number): number {
    return +Math.round(value * 10000) / 10000;
}

export class Currency {
    readonly value: number | null;

    readonly formula: string | null;

    constructor(formula: string | null, value: number | null) {
        this.value = value;
        this.formula = formula;
    }

    private get safeFormula(): string {
        if (this.formula !== null && this.formula.length > 0) {
            return `(${this.formula.substring(1)})`;
        }
        return String(this.value ?? 0);
    }

    /**
     * Deserialize a currency field that is either a formula string or a currency amount.
     * A formula string is a string that starts with an equals sign and a currency amount is a number.
     *
     * Inverse operation of "toValue".
     */
    public static fromValue(value: string | number | null): Currency | null {
        // Empty value
        if (value === "" || value === null) {
            return new Currency(null, null);
        }

        // Value contains a formula
        if (isString(value) && value.startsWith("=")) {
            let formulaResult = null;
            try {
                formulaResult = evaluateCurrencyFormula(value);
            } catch (e) {
                // nothing to do formulaResult is already null
            }
            return new Currency(value, formulaResult);
        }

        // Value contains a valid currency amount
        if (validateCurrencyValue(value)) {
            return new Currency(null, Number(value));
        }

        return null;
    }

    /**
     * Serialize this objects as a single value.
     *
     * Inverse operation of "fromValue".
     */
    public toValue(): string | number | null {
        if (this.formula !== null && this.value !== null) {
            // valid formula
            return this.formula;
        }

        return this.value;
    }

    /**
     * Computes the difference: (this - other) and returns the result as a new Currency. If any of the operands has a valid formula,
     * a new Currency is constructed using the formula: "=(thisFormula)-(otherFormula)".
     *
     * @param {Currency} other value to subtract from this
     *
     * @returns {Currency} a new Currency
     */
    public subtract(other: Currency): Currency {
        if (other.value === null || this.value === null) {
            // Either one of the operands has an invalid formula, or no value at all
            return new Currency(null, null);
        }
        // Only use formulas, if at least one exists and is valid. Fallback to the numeric values otherwise
        const hasValidFormula = this.formula !== null || other.formula !== null;
        const newFormula = hasValidFormula ? `=${this.safeFormula}-${other.safeFormula}` : roundCurrency(this.value - other.value);
        const result = Currency.fromValue(newFormula);
        if (result == null) {
            throw new Error(`Formula merging failed: ${newFormula}`);
        }
        return result;
    }

    /**
     * Computes the sum: (this + other) and returns the result as a new Currency. If any of the operands has a valid formula,
     * a new Currency is constructed using the formula: "=(thisFormula)+(otherFormula)".
     *
     * @param {Currency} other value to add to this
     *
     * @returns {Currency} a new Currency
     */
    public add(other: Currency): Currency {
        if (other.value === null || this.value === null) {
            // Either one of the operands has an invalid formula, or no value at all
            return new Currency(null, null);
        }
        // Only use formulas, if at least one exists and is valid. Fallback to the numeric values otherwise
        const hasValidFormula = this.formula !== null || other.formula !== null;
        const newFormula = hasValidFormula ? `=${this.safeFormula}+${other.safeFormula}` : roundCurrency(this.value + other.value);
        const result = Currency.fromValue(newFormula);
        if (result == null) {
            throw new Error(`Formula merging failed: ${newFormula}`);
        }
        return result;
    }

    /**
     * Create a negated version of this Currency. Values are multiplied with -1, formulas are wrapped with "=-(...)".
     *
     * For formulas, some effort is done to make this operation invertible, e.g. if an already negated formula is passed in, the parenthesis
     * and minus are removed instead of wrapping the whole formula again.
     *
     * Examples:
     * * 3000 -> -3000
     * * -3000 -> 3000
     * * =3000/2 => =-(3000/2)
     * * =-(3000/2) => =3000/2
     * * =2000 => =-(2000)
     * * =-(2000) => =2000
     *
     * @returns {Currency} as new Currency object with the negated value
     * @memberof Currency
     */
    public negate(): Currency {
        if (this.value === null) {
            return new Currency(null, null);
        }

        let newValue: string | number = -this.value;
        if (this.formula !== null) {
            const negatedFormulaMatch = NEGATED_FORMULA_REGEX.exec(this.formula);
            newValue = negatedFormulaMatch !== null ? `=${negatedFormulaMatch[1]}` : `=-${this.safeFormula}`;
        }

        const result = Currency.fromValue(newValue);
        if (result === null) {
            throw new Error("Negation failed");
        }
        return result;
    }

    public equals(other: Currency): boolean {
        return other.formula === this.formula && other.value === this.value;
    }
}

/**
 * Validate if passed string value matches the criteria for an exchange rate value, which are
 * - at most 10 digits before decimal point
 * - at most 6 digits behind decimal point
 *
 * Does NOT validate, if it's value is strictly greater than zero.
 */
export function validateExchangeRateBoundaries(input: string): boolean {
    // make sure to have at most 6 digits behind decimal point
    if (!isDecimal(input, { decimal_digits: "0,6" })) {
        return false;
    }

    // make sure not more than 10 digits before decimal point)
    return isFloat(input, { max: 1e10 });
}
