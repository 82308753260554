export enum MeasureNotificationActions {
    ENABLE = "enable",
    DISABLE = "disable",
}

// Tri-state solution to reflect the three possible states of a measure notification
export enum MeasureNotificationState {
    DISABLED = 0, // Disabled by system or by another user ("soft disable"); current default
    ENABLED = 1, // Enabled
    DISABLED_BY_OWNING_USER = 2, // Disabled by the user to whom the notification belongs ("hard disable")
}
