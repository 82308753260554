import { z } from "zod";
import { MaxYear, MinYear } from "../constants";
import { zFilterDefinition } from "./filters";

export enum LiveRunUpGranularity {
    Month = "month",
    Quarter = "quarter",
    HalfYear = "half-year",
    Year = "year",
}

export const zLiveRunUpMonth = z.object({
    month: z.number().int().min(0).max(11),
    year: z.number().int().min(MinYear).max(MaxYear),
});

export type LiveRunUpMonth = z.infer<typeof zLiveRunUpMonth>;

export const zLiveRunUpRequestBody = z.object({
    filter: zFilterDefinition,
    start: zLiveRunUpMonth,
    end: zLiveRunUpMonth,
    xAxisGranularity: z.nativeEnum(LiveRunUpGranularity),
    accumulationStart: zLiveRunUpMonth.nullable(),
});

export type LiveRunUpRequestBody = z.infer<typeof zLiveRunUpRequestBody>;

const zLiveRunUpRow = z.object({
    value: z.number(),
    timeRange: zLiveRunUpMonth.extend({
        duration: z.nativeEnum(LiveRunUpGranularity),
    }),
    stackProperty: z.string().min(1),
    stackValue: z.string().or(z.number()),
});
export type LiveRunUpRow = z.infer<typeof zLiveRunUpRow>;

export const zLiveRunUp = z.array(zLiveRunUpRow);
export type LiveRunUpDto = z.infer<typeof zLiveRunUp>;
